<template>
    <BaseExpandTransition>
        <div v-if="!online" class="network-banner flex space-between" :key="$i18n.locale">
            <div>{{ $t("error.you_are_offline") }}</div>
            <div @click="online = true">X</div>
        </div>
    </BaseExpandTransition>
</template>
<script>
export default {
    data() {
        return {
            online: navigator ? navigator.onLine : true,
        }
    },
    destory() {
        window.removeEventListener("online", this.updateOnlineStatus)
        window.removeEventListener("offline", this.updateOnlineStatus)
    },
    mounted() {
        window.addEventListener("online", this.updateOnlineStatus)
        window.addEventListener("offline", this.updateOnlineStatus)
    },

    methods: {
        updateOnlineStatus() {
            this.online = navigator.onLine
        },
    },
}
</script>

<style lang="scss">
.network-banner {
    z-index: 1000;
    top: 0;
    left: 0;
    color: #fff;
    position: fixed;
    padding: 2px 10px 2px 10px;
    width: 100%;
    background: #fa755a;
}
</style>

export default {
    lang: "en",
    meta: {
        default: {
            middle: "Affordable eSIM for Your International Travel - Stork Mobile",
            short: "Stork Mobile",
            description:
                "Enjoy low-cost mobile internet with Stork Mobile eSIM when traveling internationally. The eSIM technology helps travelers access internet in a smart and seamless way.",
        },
        home: {
            title: "Affordable eSIM for Your International Travel - Stork Mobile",
            description:
                "Enjoy low-cost mobile internet with Stork Mobile eSIM when traveling internationally. The eSIM technology helps travelers access internet in a smart and seamless way.",
        },
        plans: {
            title: "Data Plans",
            description:
                "Stork Mobile 4G/LTE Prepaid Data Plans for Europe, North America, Oceania, and (part of) Asia. Covers UK, France, Spain, Italy, Germany, Netherlands, Sweden, USA, Canada, Australia, etc..",
        },
        get_started: {
            title: "Getting Started",
            description:
                "This page explains how to use Stork Mobile eSIM step by step. From buying a data plan to installing eSIM profile to your device, we cover them all in details.",
        },
        set_up_ios: {
            title: "Set Up Guide for iOS",
            description:
                "This page explains how to install Stork Mobile eSIM to iPhones as well as how to use it for Mobile Data when traveling.",
        },
        set_up_ipad: {
            title: "Set Up Guide for iPad",
            description:
                "This page explains how to install Stork Mobile eSIM to iPads as well as how to use it for Mobile Data when traveling.",
        },
        set_up_android: {
            title: "Set Up Guide for Android",
            description:
                "This page explains how to install Stork Mobile eSIM to Android phones and how to use it for Mobile Data when traveling.",
        },
        compatibility: {
            title: "eSIM Supported Devices",
            description:
                "A list of eSIM supported devices that are compatible with Stork Mobile eSIM for mobile data",
        },
        terms: {
            title: "Terms of Use",
            description: "Terms of Use",
        },
        privacy: {
            title: "Privacy Policy",
            description: "Privacy Policy",
        },
        contact: {
            title: "Contact Us",
            description:
                "Should you have any questions about Stork Mobile, feel free to contact us at any time.",
        },
        faq: {
            title: "FAQ & Troubleshooting",
            description: "Frequently Asked Questions and Troubleshooting for Stork Mobile eSIM",
        },
        cookie_policy: {
            title: "Cookie Policy",
            description: "Cookie Policy",
        },
        reviews: {
            title: "Customer Reviews",
            description:
                "Stork Mobile eSIM Customer Reviews from acutual users with real experiences",
        },
    },
    label: {
        days: " | {n} day | {n} days",
        pick_country_region: "Select a Country or Region",
        regions: "Regional Plans",
        countries: "Country Plans",
        plan_data: "Data",
        plan_duration: "Duration",
        plan_price: "Price",
        coverage_info: "Coverage Details",
        total: "Total",
        you_selected: "Selected Plan",
        full_name: "Name",
        email: "Email",
        one_time_password: "One-Time Password",
        manufacturer: "By",
        os_version: "OS Version",
        product: "Product",
        contact_form: "Contact Form",
        subject: "Subject",
        message: "Message",
        devices: "Devices",
        password: "Password",
        coming_soon: "Coming Soon",
        device: "Your device model",
        filter_review_by_country: "Filter by country",
        review_by_at: "by {name}, submitted on {date}",
        used_in: "Used in {flags}",
        link_ipad: "iPad",
        link_under_17_3: "iPhone running iOS 17.3 or below",
        supported_countries: "{n} country covered | {n} country covered | {n} countries covered",
        percent_users: "{n}% of total users",
        unlimited: "Unlimited",
    },
    button: {
        select: "Select",
        log_in: "Log In",
        sign_up: "Sign Up",
        verify_otp: "Verify Email Address",
        retry: "Try again",
        view_our_plans: "View Data Plans",
        coverage_network: "Coverage/Network",
        close: "Close",
        network: "Network Providers",
        our_plans: "See our plans",
        next: "Next",
        pay: "Pay",
        send: "Send",
        back_to_home: "Go back to Home",
        open_app: "Open App",
        hide_privacy_banner: "Got It",
        show_all: "Show all",
        hide: "Close",
        open_image: "Open Image",
        load_more: "Load more",
        read_more_reviews: "Read more reviews",
        retry_payment: "Retry",
        register: "Register",
        go_to_checkout: "Go To Checkout",
    },
    menu: {
        getting_started: "Getting Started",
        plans: "Plans & Pricing",
        about: "About",
        sct: "特商法に基づく表示",
        privacy_policy: "Privacy Policy",
        terms_use: "Terms of Use",
        contact: "Contact Us",
        menu: "Menu",
        setup: "Set Up Guide",
        setup_ios: "Set Up Guide for iOS",
        setup_android: "Set Up Guide for Android",
        compatibility: "Compatibility",
        faq: "FAQ",
        go_to_landing: "See Stork Mobile Web site",
        cookie_policy: "Cookie Policy",
        reviews: "Reviews",
    },
    title: {
        destinations: "Works in Europe, North America, Oceania, and Asia.",
        destinations_sub: "And more regions coming soon.",
        countries: "Country Plans",
        regions: "Regional Plans",
        categories: "Categories",
        compatibility: "Compatibility",
        terms: "Terms of Use",
        privacy_policy: "Privacy Policy",
        cookie_policy: "Cookie Policy",
        reviews: "Customer Reviews",
        our_customers_in_the_world: "Our Customers Connected In The World",
        our_customers_in_the_world_sub: "People are already enjoying our eSIM to access internet.",
    },

    error: {
        email_not_similar: "Enter the same email address",
        not_valid_email: "Not valid",
        not_valid_name: "Please enter your name",
        not_valid_one_time_password: "Please enter a correct one-time password",
        password_min_length: "Password must be at least {n} characters long",
        unknown: "An unexpected error occurred",
        payment: {
            incomplete_number: "Your card number is incomplete.",
            incomplete_expiry: "Your card's expiration date is incomplete.",
            incomplete_cvc: "Your card's security code is incomplete.",
            incomplete_zip: "Your postal code is incomplete.",
            incorrect_number: "The card number is incorrect.",
            invalid_number: "The card number is not a valid credit card number.",
            invalid_expiry_month: "The card's expiration month is invalid.",
            invalid_expiry_month_past: "The card's expiration month is invalid.",
            invalid_expiry_year: "The card's expiration year is invalid.",
            invalid_expiry_year_past: "The card's expiration year is invalid.",
            invalid_cvc: "The card's security code is invalid.",
            expired_card: "The card has expired.",
            incorrect_cvc: "The card's security code is incorrect.",
            incorrect_zip: "The card's zip code failed validation.",
            card_declined: "The card was declined.",
            missing: "There is no card on a customer that is being charged.",
            processing_error: "An error occurred while processing the card.",
            rate_limit:
                "An error occurred due to requests hitting the API too quickly. Please let us know if you're consistently running into this error.",
            unknown: "An unexpected error occurred",
            network_error:
                "Network error. Please check your inbox to see if the order has been processed before trying again.",
            payment_intent_authentication_failure: "We were unable to authenticate your payment.",
        },
        invalid_email: "Please enter a valid Email.",
        contact_error:
            "If you fail to connect after trying several times, can you please contact us at support@storkmobile.com",
        you_are_offline: "You are currently offline",
    },
    message: {
        enter_credit_card_info: "Enter your card details:",
        success_new_profile_install_qrcode:
            "To install your new eSIM scan the QR code above or enter manually the following: smdp address: {smdpAddress} , matching ID: {matchingId}",
        added_to_existing_account: "Your eSIM has been added to your account ({email}).",
        new_account_created:
            "Your eSIM has been added to your newly created account ({email}). You will receive an email to verify your email address. Please click the link in the email to activate your account.",
        privacy_policy_banner:
            "We use cookies and analysis tools to improve the friendliness of our website",
        esim_being_generated:
            "Currently, the process to generate your eSIM is taking longer than usual.",
        we_will_send_esim:
            "QR code will be emailed you when it's ready. In case of any urgency, do not hesitate to contact us.",
        pay_with_apple_pay: "Pay with Apple Pay:",
        or_pay_with_credit_card: "Or enter your card details:",
        one_time_password_sent:
            "We just sent you a temporary login code. Please check your inbox. Can't find it?",
    },
    pages: {
        get_started: {
            title: "Getting Started",
            compatibility: "Device Compatibility",
            make_sure_device:
                "Please make sure your device is unlocked and listed on our {link} page.",
            esim_supported_devices: "eSIM supported devices",
            steps: {
                "01": {
                    title: "Select a Data Plan",
                    text: "Go to our {link} page and choose a plan that suits your needs.",
                    link: "Data Plans",
                },
                "02": {
                    title: "Pay with Card",
                    text:
                        "We use an online payment service with the highest level of certified security. All major credit and debit cards can be accepted.",
                },
                "03": {
                    title: "Install eSIM Profile",
                    text:
                        "Once your data plan purchase is complete, you will receive a confirmation email with a QR code for your eSIM profile installation. Simply scan the QR code and add the Cellular Plan to your phone.",
                    link_ios: "For iOS devices",
                    link_android: "For Android devices",
                },
                "04": {
                    title: "You're Online!",
                    text:
                        "At this point, your eSIM is ready to connect. Make sure you set Stork Mobile eSIM for Cellular Data, and turn your Data Roaming on.",
                    link_ios: "For iOS devices",
                    link_android: "For Android devices",
                },
            },
            dashboard: {
                title: "Control Your eSIM With Our App",
                balance: {
                    title: "Check Your Data Balance",
                    text: "Check your eSIM status and how much data you’ve used at anytime.",
                },
                topup: {
                    title: "Data Recharge",
                    text: "When you need it, add more data on your eSIM on the go.",
                },
                multiple: {
                    title: "Manage Multiple eSIM",
                    text:
                        "You can register as many eSIM as you want to your account and manage them in one app.",
                },
            },
            app: {
                title: "Use Exactly Like a Mobile App",
                text:
                    "Our Progressive Web App allows you to add to your mobile device’s home screen and works like a native mobile app. Highly recommended for easy access when traveling.",
                android: {
                    text:
                        "Visit Stork Mobile App from Chrome browser and open Menu from the top right on the screen.\n" +
                        "Tap Add to Home screen option in the menu, and tap Add again for confirmation.\n" +
                        "You can do this on any page of our app, before and after the login.\n" +
                        "(If you happen to see the Add to Home screen info bar at the bottom of the page, simply tap Add.)",
                },
                iphone: {
                    text:
                        "Visit Stork Mobile App from Safari browser and tap the Share button at the bottom, scroll left to tap Add to Home Screen option. You can do this on any page of our app, before and after the login.",
                },
            },
        },
        plans: {
            top_text:
                "Enjoy affordable mobile data in countries across Europe, Asia, North America, and Oceania. With our prepaid system, there’s no need to worry about surprise high charges.",
        },

        home: {
            title: "AFFORDABLE eSIM FOR YOUR INTERNATIONAL TRAVEL",
            sub_title:
                "Stork Mobile re-invents how you stay connected <br />anywhere, all the time",
            hero_title: "Smart & Seamless.<br>Connecting Today With The Future.",
            hero_text: `With Stork Mobile, you can enjoy low-cost mobile internet when traveling internationally. <br>
                        No longer a need to swap SIM cards or to risk losing one. eSIM technology keeps you connected like a charm.`,
            manage_title: `Manage Your eSIM<br>
            With Our Intuitive App`,
            manage_text:
                "The powerful yet easy-to-use Stork Mobile App keeps you up  to date on everything about your eSIM. It’s packed with tons of features for you to have full control.",
            how_it_works: {
                title: "How It Works",
                first_time_user: {
                    toggle: "First time user",
                    "01": {
                        title: "Select a Data Plan",
                        text:
                            "Find and buy the best data plan for you from our website. All major credit cards are accepted.",
                    },
                    "02": {
                        title: "Download eSIM Profile",
                        text:
                            "Once your data plan purchase is complete, we will send you a QR code instantly. Scan it to install Stork Mobile eSIM profile to your device.",
                    },
                    "03": {
                        title: "You're Online!",
                        text:
                            "You are good to go by this point. Turn on data roaming when you arrive in the country that is in your selected data plan.",
                    },
                },
                recurrent_user: {
                    toggle: "Returning user",
                    "01": {
                        title: "Log In",
                        text: "Log in to Stork Mobile App with your account information.",
                    },
                    "02": {
                        title: "Buy a data plan",
                        text:
                            "Find and buy your data plan on the App. Once your data plan purchase is complete, it will be instantly loaded on your eSIM and it’s all set.",
                    },
                    "03": {
                        title: "You're Online!",
                        text:
                            "Set the Stork Mobile eSIM in your device for Mobile Data, and turn on data roaming when you arrive in the country that is in your selected data plan.",
                    },
                },
            },
        },

        contact: {
            title: "Contact",
            thanks: {
                title: "Thank you for your contact!",
                text:
                    "Our customer support team will check your inquiry and forward it to the best person when necessary. We'll get back to you as soon as we can.",
                last: "Stork Mobile Customer Support",
                note:
                    "* If you do not receive our reply in 2 days, please contact us again as there might be a system problem.",
            },
            message_note: "* Please provide as much detail as possible.",
        },
        faq: {
            faq: "Frequently Asked Questions",
            troubleshooting: "Troubleshooting",
            button: {
                apn_ios: "APN set up for iOS",
                apn_android: "APN set up for Android",
                install_ios: "How To Use eSIM on iOS",
                install_android: "How To Use eSIM on Android",
            },
            category: {
                general: "General",
                plan_profile: "About Data Plans, QR Code and eSIM Profile",
                data: "About Using eSIM For Mobile Data",
            },
        },
        compatibility: {
            top_text: "Your device must be unlocked and support eSIM.",
        },
    },
    faq: {
        "01": {
            question: "Can I use tethering (Personal Hotspot)?",
            answer:
                "<p>Yes, you can use it with your Stork Mobile eSIM as long as it is enabled on your device.</p>",
        },
        "02": {
            question: "Can I make phone calls or send SMS?",
            answer:
                "<p>Stork Mobile eSIM is for data use only. So it does not come with a mobile phone number to make phone calls or send SMS. However, you can use VoIP apps such as WhatsApp or Skype to make voice calls and text communications.</p>",
        },
        "03": {
            question: "Which devices can be used with Stork Mobile eSIM?",
            answer:
                "<p>iOS/iPadOS and Android devices that are eSIM-compatible AND unlocked can be used with Stork Mobile’s eSIM. Devices which are SIM locked can’t be used.</p><p>For the full list, see {compatibility}.",
        },
        "04": {
            question: "Are any special settings required to use eSIM?",
            answer:
                "<p>Stork Mobile's eSIM uses roaming communication on your device. So, when using it, enable Roaming from settings. APN setting adjustments are required for Android devices.</p>",
        },
        "05": {
            question: "Does the eSIM itself have an expiration date?",
            answer:
                "<p>Stork Mobile eSIM is valid for 180 days from the day the last data plan or data top up was purchased for that eSIM. You can check the expiration date on the app.</p>",
        },
        "06": {
            question: "How can I create an account?",
            answer:
                "<p>Your account will be created when you purchase your first data plan through our website. You cannot create an account without purchasing a data plan.</p>",
        },
        "07": {
            question: "I bought a data plan but my trip was canceled. Can I get a refund?",
            answer:
                "<p>We can give you a full refund as long as the data plan has not been used or activated. Even after canceling the data plan, you still can keep the eSIM profile you installed as well as your Stork Mobile account for future use.</p>",
        },
        "08": {
            question: "What methods of payment do you accept?",
            answer:
                "<p>We accept all the major credit or debit cards including VISA, Mastercard, American Express.</p>",
        },
        "09": {
            question: "Can I get a receipt for the data plan I purchased?",
            answer:
                "<p>You can download the receipt from the app, under the History section of your eSIM.</p>",
        },
        "10": {
            question: "What’s the internet speed I can expect?",
            answer:
                "<p>The download / upload speed really depends on the country’s network infrastructure. You can expect, more or less, the average internet speed in the country of use.</p>",
        },
        "11": {
            question: "How long does it take to connect to the internet?",
            answer:
                "<p>As long as you set up an APN and Data Roaming correctly on your device, it should connect to the internet in 1 or 2 minutes.</p>",
        },
        "12": {
            question: "How do I set up an APN?",
            answer:
                "<p>APN settings are only required for Android devices. Please check the following for detailed instructions.</p>",
        },
        "13": {
            question: "Can I get a QR code for an eSIM profile before buying a data plan?",
            answer: "<p>No, sorry, you cannot.</p>",
        },
        "14": {
            question: "Can I use the same QR code to install the eSIM profile on multiple devices?",
            answer:
                "<p>No, you cannot. Each QR code is tied to a unique eSIM number and can only be used once. If you want to use the eSIM on more than one device, you need to buy a new eSIM and install it separately.</p>",
        },
        "15": {
            question: "When does the data plan I purchased start?",
            answer:
                "<p>The data plan is ready to use immediately after purchase. The validity of the plan begins when it is used for the first time in a country where the plan is applicable.</p>",
        },
        "16": {
            question:
                "What happens if I buy a new data plan while the current data plan is still active?",
            answer:
                "<p>The new data plan will override the current one, so the current one will end and dissipate automatically by the action of purchasing a new data plan. For that reason, if you happen to need to switch over between two different data plans while in use, then we suggest that you install a new eSIM for the second data plan.</p><p>To get a new eSIM simply, log in to our app and scroll down to “My eSIM” tab and tap “More”.</p>",
        },
        "17": {
            question: "How do I check data balance or plan expiration?",
            answer: "<p>You can check them easily on the app.</p>",
        },
        "18": {
            question: "Can I add more data to a plan before I use up the data balance?",
            answer:
                "<p>Yes, absolutely. When you have an active data plan, our app will show a “Data Recharge” button, tap it and you can proceed to buy additional data from there. Once purchase is complete, the data will be added on top of your current data remaining and the expiration will also be extended.</p>",
        },
        "19": {
            question:
                "Do I need to be connected to WiFi when I want to buy a new data plan after the previous plan has ended?",
            answer:
                "<p>Yes indeed. When the data plan ends, your eSIM will be offline so you will need an internet connection in order to buy a new data plan.</p>",
        },
        "20": {
            question: "Can I transfer an installed eSIM to another device (or a new device)?",
            answer:
                "<p>At this time, Stork Mobile does not support the transfer of eSIMs between devices. Additionally, due to security protocols, the QR code used for eSIM profile download can only be used once and therefore, cannot be repurposed.</p>",
        },
        "21": {
            question: "Does the QR code for installing the eSIM profile have an expiration date?",
            answer: "<p>The QR code expires 180 days from the date of issuance.</p>",
        },
        "22": {
            question:
                "I’ve got “Could not activate mobile data network” “PDP authentication failure” error on my device.",
            answer:
                "<p>If rebooting your device does not solve the issue, go to Settings -> General -> Reset -> Reset Network Settings on your phone to reset the network setting. Then re-enter the APN settings again. (Note that this will reset all the networks setting including the saved WiFi password, etc..)</p>",
        },
        "23": {
            question: "Is there an expiration date for an unused data plan?",
            answer:
                "<p>Yes, you must start using your data plan within 180 days from the date of purchase. (This is because the eSIM itself expires 180 days after the purchase date.)</p>",
        },
        "24": {
            question: "Are there any additional costs besides the data plan fees?",
            answer:
                "<p>No, there are none. Also, since all our data plans are prepaid, there are no maintenance or fixed costs involved.</p>",
        },
        "25": {
            question: "Can I extend the data plan expiration that I’m currently using?",
            answer:
                "<p>You can extend it by purchasing an additional data plan (top-up). This is the only way you can extend the existing data plan’s duration.</p>",
        },
        "26": {
            question: "Can I keep my eSIM and buy a data plan every time I travel abroad?",
            answer:
                "<p>Yes. Our data plans are all prepaid, so you can buy a new data plan from the app every time you go traveling and continue using your eSIM. It does not cost you anything when you are not using our data service.</p>",
        },
        "27": {
            question: "When does the data plan duration start?",
            answer:
                "<p>It starts when the first internet access is made in the designated country.</p>",
        },
        "28": {
            question: "Can I buy additional data when/if needed?",
            answer:
                "<p>Yes, absolutely. When you have an active data plan, the App will show a “Data Top Up” button, tap it and you can proceed to buy additional data plan from there. Once purchase is complete, the data will be added on top of your current data remaining and the expiration will also be extended.</p>",
        },
    },
    troubleshooting: {
        "01": {
            question: "I bought a data plan from the website but have not received an email.",
            answer:
                "<p>If you cannot find it in your spam folder as well then please <Contact Us> with your name, email address and the date/time of the purchase.</p>",
        },
        "02": {
            question:
                "Stork Mobile does not show up as a carrier when installing the eSIM profile.",
            answer:
                "<p>Yes, depending on the device or OS version, this happens. But it does not affect or hinder its capability in anyway so please be assured and use normally.</p>",
        },
        "03": {
            question: "I’m unable to use my eSIM…",
            answer:
                "<p>Please try the following troubleshooting. For more details on each, please log in to App and find Troubleshooting under menu.</p><ol><li>Manually select a local network</li><li>Specify 3G or 4G in the settings</li><li>Disable the Dual SIM mode (enable the eSIM only for cellular data)</li><li>Deactivate the Low Data Mode</li><li>Remove the installed APN profile if any</li></ol>",
        },
        "04": {
            question: "I have only one device and cannot scan the QR code.",
            answer:
                "<p>In that case please manually add the eSIM by entering the necessary code(s) provided with the QR code in the email.</p>",
        },
        "05": {
            question: "I cannot find Stork Mobile App on App Store…",
            answer:
                "<p>Our app is Progressive Web App (PWA) so it’s a browser-based. This is a strategic decision so that when needed we can deploy changes more quickly almost in real time without any App Store’s restrictions.Please access our app (app.storkmobile.com) from browser app in your phone.<br />You can also <a class='ts-underline' href='/getting-started/#addHomeScreen'>install it to homescreen</a> and use it like a mobile app.",
        },
    },
}

<template>
    <div>
        <template v-for="(lang, index) in langs">
            <i18n-link
                :key="lang.code"
                :class="['lang-switch', { active: $i18n.locale == lang.code }]"
                class="lang-switch"
                :to="lang.to"
            >
                <span>{{ lang.name }}</span>
            </i18n-link>
            <template v-if="langs.length > index + 1">
                &nbsp; | &nbsp;
            </template>
        </template>
    </div>
</template>

<script>
export default {
    computed: {
        langs() {
            if (this.$route.name) {
                return [
                    { name: "EN", code: "en", to: this.localPath("en", this.$route) },
                    {
                        name: "JA",
                        code: "ja",
                        to: this.localPath("ja", this.$route),
                    },
                ]
            }
            return ""
        },
    },
    methods: {
        localPath(locale, route) {
            return {
                name: route.name.replace(/_(en|ja)$/, ""),
                params: route.params,
                query: route.query,
                locale,
            }
        },
    },
}
</script>

<template>
    <div>
        <template v-if="region.regions">
            <div v-if="region.info" class="mb-1">
                <span v-html="$options.filters.nl2br(region.info)"></span>
            </div>
            <div class="list-group">
                <div
                    class="list-group-item short flex"
                    v-for="country in region.countries"
                    :key="`plan_coverage_${country.code}`"
                >
                    <div>
                        <picture class="flag flag-small">
                            <source
                                v-lazy="`/img/flags/${country.code.toLowerCase()}.webp`"
                                srcset="/img/flags/earth.webp"
                                type="image/webp"
                            />
                            <source
                                v-lazy="`/img/flags/${country.code.toLowerCase()}.png`"
                                srcset="/img/flags/earth.png"
                                type="image/png"
                            />
                            <img src="/img/flags/earth.png" :alt="country.name" />
                        </picture>
                    </div>
                    <div>
                        <span class="ts-bold">{{ country.name }}</span>
                        <span class="ts-small ts-quiet" v-if="country.info"
                            >&nbsp;{{ country.info }}</span
                        >
                        <div class="ts-quiet ts-small">{{ displayNetwork(country.networks) }}</div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div v-if="region.countries[0].info" class="mb-1">
                <span v-html="$options.filters.nl2br(region.countries[0].info)"></span>
            </div>
            <div class="ts-quiet">{{ displayNetwork(region.countries[0].networks) }}</div>
        </template>
    </div>
</template>
<script>
export default {
    props: {
        region: { type: Object },
        networkOnly: { tyep: Boolean, default: false },
    },

    methods: {
        displayNetwork(ns) {
            return ns
                .map(n => {
                    return `${n.name} (${[
                        ...(n["2g"] && !n["3g"] ? ["2G"] : []),
                        ...(n["3g"] ? ["3G"] : []),
                        ...(n["4g"] ? ["4G/LTE"] : []),
                        ...(n["5g"] ? ["5G"] : []),
                    ].join(", ")})`
                })
                .join(", ")
        },
    },
}
</script>

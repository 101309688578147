<template>
    <div class="block-md">
        <div :class="['reviews', { 'reviews-one-column': !towColumn }]">
            <div class="review " v-for="review in reviews" :key="review.published_at + review.name">
                <div class="review-head">
                    <i18n path="label.used_in" tag="small" class="ts-quiet review-countries">
                        <span place="flags" class="flags"
                            ><span
                                class="flag"
                                v-for="country in review.countries"
                                :key="country.code + review.published_at"
                                >{{ country.flag }}</span
                            ></span
                        >
                    </i18n>
                    <div class="review-rating">
                        <span class="stars"
                            >{{ Array.from({ length: review.rating }, (x, i) => "★").join("")
                            }}<span class="grey">{{
                                Array.from({ length: 5 - review.rating }, (x, i) => "★").join("")
                            }}</span></span
                        >
                        <strong>{{ Math.round(review.rating) }}</strong>
                    </div>
                </div>
                <div class="review-body" v-if="review.review">
                    <p v-html="$options.filters.nl2br(review.review)"></p>
                </div>
                <div class="review-foot">
                    <small class="ts-quiet">
                        {{
                            $t("label.review_by_at", {
                                name: review.name,
                                date: $options.filters.dateFormat(review.published_at),
                            })
                        }}
                    </small>
                </div>
            </div>
        </div>

        <div class="block-lg ts-align-center" v-if="loading">
            <div><BaseLoader radius="35px" class="mt-10" /></div>
        </div>
        <div class="block-lg ts-align-center" v-if="showMore">
            <button
                @click="loadMore"
                v-if="hasMore || reviews.length == this.limit"
                class="button mt-10"
            >
                {{ $t("button.load_more") }}
            </button>
        </div>
    </div>
</template>

<script>
import queryString from "query-string"
export default {
    data() {
        return {
            reviews: [],
            currentPage: 1,
            loading: true,
            hasMore: false,
        }
    },
    props: {
        limit: { type: Number, default: 10 },
        country: { type: String, default: null },
        picked: { type: Boolean, default: null },
        towColumn: { type: Boolean, default: false },
        showMore: { type: Boolean, default: false },
    },
    mounted() {
        this.fetch()
    },
    watch: {
        "$i18n.locale": {
            handler() {
                this.currentPage = 1
                this.country = null
                this.reviews = []
                this.fetch()
            },
        },
        country() {
            this.currentPage = 1
            this.reviews = []
            this.fetch()
        },
    },
    methods: {
        loadMore() {
            this.currentPage += 1
            this.fetch()
        },
        async fetch() {
            this.loading = true
            const query = queryString.stringify({
                locale: this.$i18n.locale,
                limit: this.limit,
                page: this.currentPage,
                country: this.country,
                picked: this.picked,
            })
            const response = await fetch(
                `${process.env.VUE_APP_API_BASE_URL}/public/reviews?${query}`,
                {
                    method: "GET",
                }
            )
            const result = await response.json()
            this.hasMore = result.length >= this.limit

            this.reviews = this.reviews.concat(result)
            this.loading = false
        },
    },
}
</script>

export default {
    lang: "ja",
    meta: {
        default: {
            middle: "海外旅行者向けeSIMデータ通信サービス - ストークモバイル",
            short: "ストークモバイル",
            description:
                "ストークモバイルのeSIMを使えば海外旅行先でも手軽に、そして安価にインターネットを使うことができます。eSIMテクノロジーで海外での新しいデータ通信を体験しましょう！",
        },
        home: {
            title: "ホーム",
            description:
                "ストークモバイルのeSIMを使えば海外旅行先でも手軽に、そして安価にインターネットを使うことができます。eSIMテクノロジーで海外での新しいデータ通信を体験しましょう！",
        },
        plans: {
            title: "データプラン・料金",
            description:
                "ストークモバイルのデータプランは地域別プラン（ヨーロッパ、北米、オセアニア）と国別プラン（アジアの一部）が利用可能。対応国はイギリス、フランス、スペイン、イタリア、ドイツ、オランダ、スウェーデン、アメリア、カナダ、オーストラリアなど",
        },
        get_started: {
            title: "eSIM利用の流れ",
            description:
                "ストークモバイルのeSIMの使い方を紹介します。データプランの購入からeSIMプロファイルのインストール、現地での設定までステップごとに解説します。",
        },
        set_up_ios: {
            title: "iOSでのeSIMインストール/設定方法",
            description:
                "ストークモバイルのeSIMをiPhoneにインストールする方法と、海外旅行先でモバイルデータ通信を利用するのに必要な設定を解説します。",
        },
        set_up_ipad: {
            title: "iPadでのeSIMインストール/設定方法",
            description:
                "ストークモバイルのeSIMをiPhoneにインストールする方法と、海外旅行先でモバイルデータ通信を利用するのに必要な設定を解説します。",
        },
        set_up_android: {
            title: "AndroidでのeSIMインストール/設定方法",
            description:
                "ストークモバイルのeSIMをAndroid端末にインストールする方法と、海外旅行先でモバイルデータ通信を利用するのに必要な設定を解説します。",
        },
        compatibility: {
            title: "eSIM搭載端末",
            description:
                "Stork MobileのeSIMを使用できる動作保証端末の一覧です。必ず一覧に記載のある端末でご利用ください。",
        },
        sct: {
            title: "特定商取引に関する法律に基づく表示",
        },
        terms: {
            title: "利用規約",
            description: "ストークモバイルの利用規約です",
        },
        privacy: {
            title: "個人情報保護方針",
            description: "ストークモバイルの個人情報保護方針です",
        },
        contact: {
            title: "お問い合わせ",
            description: "Stork Mobileに関する質問はこちらのお問い合わせページからお送りください。",
        },
        faq: {
            title: "よくある質問 & トラブルシューティング",
            description:
                "ストークモバイルのeSIMやデータプラン、海外でのモバイルデータ通信に関するよくある質問とトラブルシューティング",
        },
        reviews: {
            title: "レビュー",
            description: "ストークモバイルのeSIMを実際に使用したユーザーからのレビュー",
        },
    },
    label: {
        days: " | {n} 日 | {n} 日",
        pick_country_region: "国あるいは地域を選択",
        regions: "地域別データプラン",
        countries: "国別データプラン",
        plan_data: "データ容量",
        plan_duration: "日数",
        plan_price: "料金",
        coverage_info: "カバレッジ",
        total: "合計",
        you_selected: "詳細",
        full_name: "お名前",
        email: "メールアドレス",
        one_time_password: "ワンタイムパスワード",
        manufacturer: "メーカー",
        os_version: "OSバージョン",
        product: "端末名",
        contact_form: "問い合わせフォーム",
        subject: "件名",
        message: "メッセージ",
        devices: "メーカーと端末名",
        password: "パスワード",
        coming_soon: "順次提供開始予定",
        device: "利用端末",
        review_by_at: "{name} が {date} に投稿",
        used_in: "{flags} で使用",
        filter_review_by_country: "国を絞り込み",
        link_ipad: "iPadの設定方法",
        link_under_17_3: "iPhone (iOS 17.3以下)の設定方法",
        supported_countries: "{n}ヶ国に対応",
        percent_users: "{n}% of total users",
        unlimited: "無制限",
    },
    button: {
        select: "選択する",
        log_in: "ログイン",
        sign_up: "アカウント登録",
        verify_otp: "メールアドレスを確認",
        retry: "再送信",
        view_our_plans: "データプラン一覧",
        coverage_network: "利用可能国/ネットワークを確認する",
        close: "閉じる",
        network: "ネットワークを確認する",
        our_plans: "データプラン一覧",
        next: "Next",
        pay: "Pay",
        send: "送信",
        back_to_home: "トップへ戻る",
        open_app: "アプリを開く",
        hide_privacy_banner: "了解",
        show_all: "すべて見る",
        hide: "閉じる",
        open_image: "画像を開く",
        load_more: "もっと見る",
        read_more_reviews: "さらにレビューを見る",
        retry_payment: "リトライする",
        register: "登録する",
        go_to_checkout: "支払いへ進む",
    },
    menu: {
        getting_started: "eSIM利用の流れ",
        plans: "データプラン・料金",
        about: "About",
        sct: "特商法に基づく表示",
        privacy_policy: "個人情報保護方針",
        terms_use: "利用規約",
        contact: "問い合わせ",
        menu: "Menu",
        setup: "設定方法",
        setup_ios: "iOS端末での設定方法",
        setup_android: "Android端末での設定方法",
        compatibility: "動作保証端末",
        faq: "よくある質問",
        go_to_landing: "Stork Mobile の Web サイトを見る",
        cookie_policy: "Cookie Policy",
        reviews: "レビュー",
    },
    title: {
        destinations: "ヨーロッパ、アジア、北米、オセアニアの国々で利用可能",
        destinations_sub: "今後さらに地域拡大予定",
        countries: "国別データプラン",
        regions: "地域別データプラン",
        categories: "カテゴリー",
        compatibility: "動作保証端末",
        terms: "利用規約",
        privacy_policy: "個人情報保護方針",
        reviews: "レビュー",
        our_customers_in_the_world: "様々な国で利用されています",
        our_customers_in_the_world_sub:
            "多くのユーザーがStork MobileのeSIMを使ってインターネットにアクセスしています。",
    },
    error: {
        email_not_similar: "同じメールアドレスを入力してください",
        not_valid: "Not valid",
        not_valid_name: "名前を入力してください",
        not_valid_one_time_password: "ワンタイムパスワードを入力してください",
        password_min_length: "{n}桁以上のパスワードを設定してください",
        unknown: "予期しないエラーが発生しました",
        payment: {
            incomplete_number: "カード番号を正しく入力してください",
            incomplete_expiry: "カードの有効期限を正しく入力してください",
            incomplete_cvc: "セキュリティーコード（CVC）を正しく入力してください",
            incomplete_zip: "郵便番号を正しく入力してください",
            incorrect_number: "カード番号を正しく入力してください",
            invalid_number: "無効なカード番号です",
            invalid_expiry_month: "有効期限を正しく入力してください",
            invalid_expiry_month_past: "有効期限を正しく入力してください",
            invalid_expiry_year: "有効期限を正しく入力してください",
            invalid_expiry_year_past: "有効期限を正しく入力してください",
            invalid_cvc: "セキュリティーコード（CVC）を正しく入力してください",
            expired_card: "このカードは有効期限切れです。",
            incorrect_cvc: "セキュリティーコード（CVC）を正しく入力してください",
            incorrect_zip: "郵便番号の確認ができませんでした",
            card_declined: "The card was declined.",
            missing: "There is no card on a customer that is being charged.",
            processing_error: "決済処理中にエラーが発生しました",
            rate_limit:
                "APIリクエスト超過のためエラーが発生しました。このエラーが継続的に発生する場合は support@storkmobile.com までご連絡ください。",
            unknown: "予期しないエラーが発生しました",
            network_error:
                "ネットワークエラーが発生しました。注文完了メールが届いているか確認し、届いていない場合は再度注文を行ってください。",
            payment_intent_authentication_failure: "決済を認証できませんでした。",
        },
        invalid_email: "有効なメールアドレスを入力してください。",
        contact_error:
            "お問い合わせ中にエラーが発生しました。このエラーをみかけた場合は、 support@storkmobile.com までご連絡ください。",
        you_are_offline: "現在、端末はオフラインです",
    },
    message: {
        enter_credit_card_info: "クレジットカード情報:",
        success_new_profile_install_qrcode:
            "To install your new eSIM scan the QR code above or enter manually the following: smdp address: {smdpAddress} , matching ID: {matchingId}",
        added_to_existing_account: "eSIMはお客様のアカウント ({email}) に登録されました。",
        new_account_created:
            "eSIMは新しく作成されたお客様のアカウント ({email}) に登録されました。メールアドレス確認用のメールをお送り致しますので、メールに内にあるリンクからアカウント作成を完了してください。",
        privacy_policy_banner:
            "私達はサイトの使いやすさを改善するために、アクセス解析ツールやCookieを利用します。",
        esim_being_generated: "現在eSIMの発行に通常より時間がかかっています。",
        we_will_send_esim:
            "eSIMの準備が完了次第、メールにてQRコードをお送り致します。急用の場合は以下より直接お問い合わせください。",
        one_time_password_sent:
            "ワンタイムパスワードを送信しました。メールボックスをご確認ください。",
    },
    pages: {

        get_started: {
            title: "Getting Started",
            compatibility: "利用できる端末",
            make_sure_device: "eSIMに対応しているSIMロックフリーの{link}でご利用ください。",
            esim_supported_devices: "動作保証端末",
            steps: {
                "01": {
                    title: "データプランを選択",
                    text: "{link}一覧から渡航先や必要データ量に合ったプランをご選択ください。",
                    link: "データプラン",
                },
                "02": {
                    title: "支払い",
                    text:
                        "クレジットカードで選択されたデータプランを購入します。（お客様のカード情報を保存することはございませんのでご安心ください。）",
                },
                "03": {
                    title: "eSIMをインストール",
                    text:
                        "データプランの購入が完了すれば、メールでeSIMプロファイルインストール用のQRコードをお送りします。各デバイスのインストール方法に従ってeSIMプロファイルをインストールしてください。",
                    link_ios: "iOS端末",
                    link_android: "Android端末",
                },
                "04": {
                    title: "インターネットに接続！",
                    text:
                        "この時点ですでにご購入いただいたデータプランの利用可能国でインターネットに接続できる状態です。モバイルデータ通信にインストールしたStork MobileのeSIMを設定し、データローミングを有効にしてご利用ください。",
                    link_ios: "iOS端末",
                    link_android: "Android端末",
                },
            },
            dashboard: {
                title: "アプリでeSIMのすべてを管理できます",
                balance: {
                    title: "データ残量や有効期限の確認",
                    text:
                        "いつでもアプリからeSIMの状態や、データ残量、有効期限などをチェックできます。",
                },
                topup: {
                    title: "データチャージ",
                    text: "必要があれば、追加のデータも簡単にアプリから購入することができます。",
                },
                multiple: {
                    title: "複数のeSIMを管理可能",
                    text:
                        "ひとつのアカウントに複数のeSIMを登録することができるので、2台以上の端末の管理も簡単です。",
                },
            },
            app: {
                title: "モバイルアプリと同じように使えます",
                text:
                    "ブラウザからホームスクリーンに追加するだけで、ストアからダウンロードすることなく通常のモバイルアプリと同じようにStork Mobileのアプリを使うことができます。",
                android: {
                    text:
                        "ChromeブラウザでStork Mobile アプリにアクセスし、右上のメニューを開きます。「ホーム画面に追加」を選択し、追加作業を完了してください。ログイン前後に関係なく、Stork Mobile アプリのどのページでもこの操作でホーム画面に追加できます。\n" +
                        "（もしページ下部にホームに追加するバナーが表示された場合は、そのバナーから簡単に操作を行って頂けます。）",
                },
                iphone: {
                    text:
                        "ブラウザでStork Mobile アプリにアクセスし、下部の共有ボタンをタップ、「ホーム画面に追加」を選択します。ログイン前後に関係なく、Stork Mobile アプリのどのページでもこの操作でモバイルアプリのようにご利用いただけます。",
                },
            },
        },
        plans: {
            top_text:
                "ヨーロッパ、アジア、北米、オセアニアの国々で低価格なモバイルデータ通信が可能です。プリペイドタイプなので高額請求の心配もありません。",
        },

        home: {
            title: "海外でeSIM使うなら<br>「ストークモバイル」",
            sub_title: "海外旅行者向けeSIMデータ通信サービス",
            hero_title: "eSIMテクノロジーで<br>より簡単、よりスマートに繋がる",
            hero_text: `Stork MobileのeSIMを使えば海外旅行先でも手軽にモバイルデータ通信を利用することができます。<br>
                        SIMカードを購入して入れ替えたり、紛失の心配をする必要はもうありません。全てをオンラインで完結できます。`,
            manage_title: `eSIMの全てを操作できる<br>
            直感的で使いやすいアプリ`,
            manage_text:
                "Stork Mobileのアプリを使えばデータ残量の確認や追加のデータチャージはもちろん、複数のeSIMも一つのアプリで管理できます。",
            how_it_works: {
                title: "使い方",
                first_time_user: {
                    toggle: "初回利用",
                    "01": {
                        title: "データプランを購入",
                        text: "旅行先や必要なデータ量から好みのデータプランを選択、購入します。",
                    },
                    "02": {
                        title: "eSIMプロファイルをインストール",
                        text:
                            "データプランの購入が完了すると、メールでQRコードをお送りします。端末でコードを読み取り、eSIMプロファイルをダウンロードします。",
                    },
                    "03": {
                        title: "モバイルデータ通信を開始",
                        text:
                            "追加したStork MobileのeSIMを端末の設定画面から選択し、データローミングを有効にすることで旅行先でインターネットが利用できます。",
                    },
                },
                recurrent_user: {
                    toggle: "2回目以降",
                    "01": {
                        title: "アプリにログイン",
                        text: "Stork Mobileのアカウントにログインします。",
                    },
                    "02": {
                        title: "データプランを購入",
                        text:
                            "アプリから好みのデータプランを選択、購入します。購入が完了するとお客様のeSIMにそのデータプランが登録され、データ通信開始準備が完了します。",
                    },
                    "03": {
                        title: "モバイルデータ通信を開始",
                        text:
                            "端末にインストールされているStork MobileのeSIMをデータ通信に設定の上、データローミングを有効にすることで、インターネットを利用できるようになります。",
                    },
                },
            },
        },

        contact: {
            title: "問い合わせ",
            thanks: {
                title: "お問い合わせありがとうございます",
                text: "お問い合わせ内容を確認後、回答いたしますのでしばらくお待ちください。",
                last: "ストークモバイル カスタマーサポート",
                note:
                    "* もし2日以内に返信がない場合、システムに障害が発生していた可能性がありますので、お手数ですが再度お問い合わせをお願いいたします。",
            },
            message_note: "* より的確な対応を行えるよう、できる限り詳細な情報をご提供ください。",
        },
        faq: {
            faq: "FAQ",
            troubleshooting: "トラブルシューティング",
            button: {
                apn_ios: "iOSでのAPN設定",
                apn_android: "AndroidでのAPN設定",
                install_ios: "iOSでの設定方法",
                install_android: "Androidでの設定方法",
            },
            category: {
                general: "サービス全般",
                plan_profile: "データプラン、QRコード、eSIMプロファイルについて",
                data: "eSIMでのデータ通信や設定に関して",
            },
        },
        compatibility: {
            top_text:
                "eSIMに対応しているSIMロックフリー端末（SIMフリー端末）、あるいはSIMロック解除端末のみご利用いただけます。",
        },
    },
    faq: {
        "01": {
            question: "テザリング（インターネット共有）は使えますか？",
            answer:
                "<p>はい、ご利用いただけます。利用時は端末の設定からテザリング機能を有効にしてください。</p>",
        },
        "02": {
            question: "電話やSMSは使えますか？",
            answer:
                "<p>Stork MobileのeSIMはデータ通信専用です。電話番号を利用した通常の電話や、SMSはご利用いただけません。<br />ただし、LINEやSkypeなどVoIPアプリを利用した電話はご利用いただけます。</p>",
        },
        "03": {
            question: "どんな端末で使えますか？",
            answer:
                "<p>iOS/iPadOS端末、Android端末ともにeSIM対応のSIMフリー端末でご利用いただけます。SIMロックがかかっている端末は使用できません。</p><p>詳しくは{compatibility}をご確認ください。</p>",
        },
        "04": {
            question: "eSIMを使用するのに何か特別な設定は必要ですか？",
            answer:
                "<p>Stork MobileのeSIMはローミング通信を利用するため、使用時は設定からローミングを有効に設定してください。また、Android端末の場合はAPN設定が必要です。</p>",
        },
        "05": {
            question: "eSIM自体に有効期限はありますか？",
            answer:
                "<p>eSIM自体の有効期限は最後にデータプランを購入した日から180日です。eSIMの有効期限はアプリ内で確認することができます。</p>",
        },
        "06": {
            question: "アカウントはどうすれば作成できますか？",
            answer:
                "<p>初回のデータプランをウェブサイトからご購入いただいたタイミングでアカウントが作成されます。（データプランを購入せず）アカウントのみの作成はできかねます。</p>",
        },
        "07": {
            question:
                "データプランを購入しましたが旅行がキャンセルになりました。返金はしていただけますか？",
            answer:
                "<p>全く使用されていない（開始されていない）未使用データプランに限り、全額返金対応致します。尚、データプランをキャンセルされた場合でも、インストールしていただいたeSIMプロファイルと作成されたアカウントはその後も引き続きご利用いただけます。</p>",
        },
        "08": {
            question: "どのような支払い方法に対応していますか？",
            answer:
                "<p>VISA、マスターカード、アメリカンエキスプレス のクレジットカードまたはデビットカードをご利用いただけます。</p>",
        },
        "09": {
            question: "領収書は発行してもらえますか？",
            answer:
                "<p>領収書はアプリのMy eSIMから該当eSIMを選択後、Historyから発行、ダウンロードしていただけます。</p>",
        },
        "10": {
            question: "データ通信のスピードはどれくらいですか？",
            answer:
                "<p>通信速度については現地の通信インフラ（ネットワーク）に大きく依存します。基本的には現地の一般的な平均通信速度とご理解ください。</p>",
        },
        "11": {
            question: "現地では購入したデータプランをすぐに使用開始できますか？",
            answer:
                "<p>必要な設定が使用する端末上で行われておれば、数分で現地ネットワークに接続されます。</p>",
        },
        "12": {
            question: "APNはどこで設定できますか？",
            answer:
                "<p>APN設定はAndroid端末でのみ必要となります。詳しい設定方法は以下をご確認ください。</p>",
        },
        "13": {
            question:
                "データプランを購入する前にeSIMプロファイルインストール用のQRコードだけ（無料で）取得することはできますか？",
            answer:
                "<p>QRコードは初回のデータプランを購入いただいた時のみお送りしております。従ってデータプランの購入前にQRコードだけを取得することはできません。</p>",
        },
        "14": {
            question:
                "同じQRコードを使って2台以上の端末にeSIMプロファイルをインストールすることはできますか？",
            answer:
                "<p>各QRコードは個別のeSIM番号に紐づいているため、1回きりしか使えません。そのため2台以上でeSIMを使いたい場合は新たにeSIMを購入し、別のeSIMをインストールしてください。</p>",
        },
        "15": {
            question: "購入したデータプランはいつ開始されますか？",
            answer:
                "<p>データプランはご購入後すぐに利用開始（通信）可能状態となります。そのデータプランが利用できる国で、初回のデータ通信が発生したタイミングでプランの有効期限がスタートします。</p>",
        },
        "16": {
            question:
                "データ容量がまだ残っている状態で新しいデータプランを購入した場合どうなりますか？",
            answer:
                "<p>その場合は新しいデータプランに完全に上書きされ、以前のデータプランは失効（終了）します。2つ以上のデータプランを使い分けたい場合は、新たに別のeSIMを取得しインストールすることで使い分けが可能です。</p><p>新しいeSIMの取得はアプリの「My eSIM」あるいは「その他」から可能です。（同時にデータプランの購入が必要です。）</p>",
        },
        "17": {
            question: "データ残量やプランの有効期限はどこで確認できますか？",
            answer: "<p>アプリにログインしてご確認ください。</p>",
        },
        "18": {
            question: "データプランが終了する前に追加のデータをチャージできますか？",
            answer:
                "<p>はい、可能です。データプラン使用中はStork Mobileアプリ上に「データを追加購入」のボタンが表示されますので、そのボタンからいつでも新たなデータプランを追加購入することができます。購入されたデータプランのデータ容量と有効期限がその時点で加算されます。</p>",
        },
        "19": {
            question:
                "データを使い切った後に新しくプランを購入する際はインターネット環境が必要ですか？",
            answer:
                "<p>はい、データプランが終了するとeSIMは圏外状態になるので、再度プランを購入する場合にはWiFiなどのインターネット環境が必要です。</p>",
        },
        "20": {
            question:
                "インストール済みのeSIMを別の端末（または新しい端末）に移すことはできますか？",
            answer:
                "<p>現在Stork MobileではeSIMの別端末への移行には対応しておりません。また、eSIMプロファイルダウンロード用のQRコードはセキュリティー上一度しか使用できない仕様のため、再利用はできません。</p>",
        },
        "21": {
            question: "eSIMプロファイルインストール用のQRコードに有効期限はありますか？",
            answer: "<p>QRコードの有効期限は発行日より180日です。</p>",
        },
        "22": {
            question:
                "「データ通信機能を起動できませんでした」「PDP認証に失敗しました」というエラーが表示されます。",
            answer:
                "<p>まず端末の再起動APNの再設定を行い、エラーが解消されるかご確認ください。次に 一般 > リセット から「ネットワーク設定をリセット」を行っていただき、その後再度APN設定を行ってください。それでもエラーが解消されない場合は、無償で新しいQRコードを発行いたしますので、{contact}よりご連絡ください。</p>",
        },
        "23": {
            question: "未使用のデータプランに有効期限はありますか？",
            answer:
                "<p>データプランは購入日から180日以内に使用を開始してください。（購入日から180日を経過するとeSIM自体の有効期限を迎えるため。）</p>",
        },
        "24": {
            question: "データプランの料金以外で何か必要な費用はありますか？",
            answer:
                "<p>一切ございません。また、データプランは全てプリペイドタイプとなりますので、維持費や固定費等もかかりません。</p>",
        },
        "25": {
            question: "購入したデータプランの有効期限を延長することはできますか？",
            answer:
                "<p>有効期限のみの延長はできませんが、追加のデータを購入いただくことで有効期限も同時に延長されます。Stork Mobile アプリ上の「データを追加購入」のボタンから新たなデータプランの購入が可能です。</p>",
        },
        "26": {
            question: "年に数回海外に行きます。その都度プランを購入して使用できますか？",
            answer:
                "<p>はい、Stork MobileのeSIMデータ通信サービスは完全プリペイド式ですので、ご旅行の度にアプリから新たなデータプランを購入することでその都度ご利用いただけます。使わない期間に料金が発生することは一切ありません。</p>",
        },
        "27": {
            question: "データプランの日数はいつからカウントが開始されますか？",
            answer:
                "<p>初回のデータ通信が行われた時点からプランの日数カウントが開始されます。（購入時点から開始されるわけではありません。）<br />最終日は初回通信開始と同時刻まで利用できます。</p>",
        },
        "28": {
            question: "購入したデータプランでは足りなかった場合、追加のデータ購入はできますか？",
            answer:
                "<p>はい、可能です。データプラン使用中はStork Mobile アプリ上に「データを追加購入」のボタンが表示されますので、そのボタンからいつでも新たなデータプランを追加購入することができます。購入されたデータプランのデータ容量と有効期限がその時点で加算されます。</p>",
        },
    },
    troubleshooting: {
        "01": {
            question: "ウェブサイトからデータプランを購入しましたがメールが届きません。",
            answer:
                "<p>迷惑フォルダなども確認してもメールが発見できない場合は恐れ入りますが{contact}より詳細（お名前、メールアドレス、購入日時など）を添えてご連絡ください。</p>",
        },
        "02": {
            question:
                "eSIMのインストール時に表示される通信事業者名にStork Mobileが表示されません。",
            answer:
                "<p>端末やOSによってeSIMプロファイルインストール時にStork Mobileの名称が表示されませんが、機能や実際の利用に際しての問題はございません。</p>",
        },
        "03": {
            question: "現地でeSIMを使った通信ができません。",
            answer:
                "<p>以下のトラブルシューティングをお試しください。尚、さらに詳しい方法はアプリのメニュー内「トラブルシューティング」からご確認いただけます。</p><ol><li>通信事業者の手動選択</li><li>4G or 3Gネットワークの指定</li><li>デュアルSIMの無効化</li><li>省データモード解除</li><li>APNプロファイルの削除</li></ol>",
        },
        "04": {
            question: "端末が一台しかなく、届いたQRコードを読み取ることができません。",
            answer:
                "<p>QRコードを読み取れない場合は、QRコードと一緒にお送りするコードを端末の設定画面に入力し、手動でeSIMを追加してください。</p>",
        },
        "05": {
            question: "App StoreでStork Mobileのアプリが見つかりません。",
            answer:
                "<p>アプリはプログレッシブウェブアプリ（PWA）というブラウザベースになります。これにより各ストアの制約に縛られることなく、必要があれば素早く変更を（ほぼリアルタイムで）アプリに反映する事ができます。<br />端末のブラウザアプリからアプリ（app.storkmobile.com）をご利用ください。<br />尚、<a class='ts-underline' href='/ja/getting-started/#addHomeScreen'>ホームスクリーンに追加</a>することでモバイルアプリと同じように使用することも可能です。</p>",
        },
    },
}

<template>
    <div class="home">
        <div class="block"></div>

        <div class="container">
            <div class="ts-measure ts-measure-center">
                <h1
                    class="page-title ts-align-center no-upercase"
                    v-html="$t('pages.home.title')"
                ></h1>
                <p class="ts-lead ts-quiet" v-html="$t('pages.home.sub_title')"></p>
            </div>
        </div>

        <div class="block-md"></div>

        <div class="slanted slanted-background-grey slanted-top-only">
            <section class="hero">
                <div class="hero-overlay">
                    <div class="container">
                        <div class="hero-inner">
                            <div class="hero-content">
                                <h2
                                    class="section-title "
                                    v-html="$t('pages.home.hero_title')"
                                ></h2>
                                <p
                                    class="ts-lead ts-measure-tight"
                                    v-html="$t('pages.home.hero_text')"
                                ></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="destinations">
                <div class="container">
                    <div class="card">
                        <div class="card-header no-border">
                            <div class="card-header-body">
                                <h2 class="section-title">
                                    {{ $tc("title.destinations", countries.length) }}
                                </h2>
                                <p class="ts-lead ts-quiet">{{ $t("title.destinations_sub") }}</p>
                            </div>
                            <div class="card-header-push">
                                <i18n-link :to="{ name: 'plans' }" class="button">{{
                                    $t("button.view_our_plans")
                                }}</i18n-link>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="destinations-grid">
                                <div
                                    class="destinations-item"
                                    v-for="country in limitedCountries"
                                    :key="country.id"
                                >
                                    <picture class="flag">
                                        <source
                                            :srcset="`/img/flags/${country.id.toLowerCase()}.webp`"
                                            type="image/webp"
                                        />
                                        <source
                                            :srcset="`/img/flags/${country.id.toLowerCase()}.png`"
                                            type="image/png"
                                        />
                                        <img
                                            class="flag"
                                            src="/img/flags/earth.png"
                                            :alt="country.name"
                                        />
                                    </picture>
                                    <span>{{ country.name }}</span>
                                </div>

                                <div
                                    class="destinations-item hide-xl hide-md ts-align-center"
                                    style="border-right:none"
                                ></div>
                                <div
                                    class="destinations-item hide-xl hide-md ts-align-center"
                                    style="justify-content: center;"
                                    v-if="!showMore"
                                >
                                    <button class="button " @click="showMore = !showMore">
                                        <template v-if="showMore">
                                            {{ $t("button.hide") }}
                                        </template>
                                        <template v-else>
                                            {{ $t("button.show_all") }}
                                        </template>
                                    </button>
                                </div>
                            </div>
                            <div
                                :class="[
                                    { 'show-xl-over show-sm-below': !showMore },
                                    'ts-align-center',
                                ]"
                            >
                                <button class="button mb-2 mt-2" @click="showMore = !showMore">
                                    <template v-if="showMore">
                                        {{ $t("button.hide") }}
                                    </template>
                                    <template v-else>
                                        {{ $t("button.show_all") }}
                                    </template>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <section class="app-promo">
            <div class="container">
                <div class="ts-measure-100-50">
                    <h2 class="section-title" v-html="$t('pages.home.manage_title')"></h2>
                    <p class="ts-lead ts-quiet" v-html="$t('pages.home.manage_text')"></p>
                    <div class="mt-2 app-promo-badges">
                        <a
                            class="badge-wrap"
                            href="https://apps.apple.com/us/app/stork-mobile-esim-app/id1490956916"
                            target="_blank"
                        >
                            <img
                                class="badge"
                                v-lazy="require('@/assets/images/App_Store_badge_EN.svg')"
                                alt="Download for iPhone"
                            />
                        </a>
                        <a
                            class="badge-wrap"
                            href="https://play.google.com/store/apps/details?id=com.storkmobile.app"
                            target="_blank"
                        >
                            <img
                                class="badge"
                                v-lazy="require('@/assets/images/Google_Play_Store_badge_EN.svg')"
                                alt="Download for Android"
                            />
                        </a>
                    </div>
                </div>
            </div>
            <div class="app-promo-device-mobile show-tablet" style="min-height:340px">
                <picture>
                    <source
                        v-lazy="require('@/assets/images/mobile-device-flat.webp')"
                        type="image/webp"
                    />
                    <source
                        v-lazy="require('@/assets/images/mobile-device-flat.png')"
                        type="image/png"
                    />
                    <img v-lazy="require('@/assets/images/mobile-device-flat.png')" alt="iPhone" />
                </picture>
            </div>
            <section class="app-promo-device">
                <div class="device-isometric">
                    <img v-lazy="require('@/assets/images/device-isometric.png')" alt="iPhone" />
                </div>
            </section>
        </section>

        <section class="hiw">
            <div class="container">
                <h2 class="section-title">{{ $t("pages.home.how_it_works.title") }}</h2>
                <div class="block-md">
                    <div class="toggle-wrap">
                        <input
                            type="checkbox"
                            class="toggle-control"
                            name="hiw-switcher"
                            id="hiw-switcher"
                            @change="toggleHowItWorks"
                        />
                        <label class="toggle" for="hiw-switcher">
                            <div class="toggle-handle"></div>
                            <span
                                :class="['toggle-label', { active: 'first-time-user' == toggle }]"
                                >{{ $t("pages.home.how_it_works.first_time_user.toggle") }}</span
                            >
                            <span
                                :class="['toggle-label', { active: 'recurrent-user' == toggle }]"
                                >{{ $t("pages.home.how_it_works.recurrent_user.toggle") }}</span
                            >
                        </label>
                    </div>
                </div>
                <div class="block-xl">
                    <transition name="fade" mode="out-in">
                        <div
                            class="grid"
                            id="new-user"
                            v-if="toggle == 'first-time-user'"
                            key="first-time-user"
                        >
                            <div class="grid-item">
                                <div class="hiw-step-header">
                                    <h3 class="hiw-step-number">01</h3>
                                    <img
                                        height="34"
                                        src="@/assets/images/circle-pad-finger.svg"
                                        alt="Icon pad"
                                    />
                                </div>
                                <div class="hiw-step-body">
                                    <h3>
                                        {{ $t("pages.home.how_it_works.first_time_user.01.title") }}
                                    </h3>
                                    <p>
                                        {{ $t("pages.home.how_it_works.first_time_user.01.text") }}
                                    </p>
                                    <i18n-link
                                        :to="{ name: 'plans' }"
                                        class="button button-secondary button-small button-link"
                                        >{{ $t("button.our_plans") }}</i18n-link
                                    >
                                </div>
                            </div>
                            <div class="grid-item">
                                <div class="hiw-step-header">
                                    <h3 class="hiw-step-number">02</h3>
                                    <img
                                        height="34"
                                        src="@/assets/images/qr-code-scan.svg"
                                        alt="icon QR code"
                                    />
                                </div>
                                <div class="hiw-step-body">
                                    <h3>
                                        {{ $t("pages.home.how_it_works.first_time_user.02.title") }}
                                    </h3>
                                    <p>
                                        {{ $t("pages.home.how_it_works.first_time_user.02.text") }}
                                    </p>
                                </div>
                            </div>
                            <div class="grid-item">
                                <div class="hiw-step-header">
                                    <h3 class="hiw-step-number">03</h3>
                                    <img
                                        height="34"
                                        src="@/assets/images/phone-action-check.svg"
                                        alt="icon phone check"
                                    />
                                </div>
                                <div class="hiw-step-body">
                                    <h3>
                                        {{ $t("pages.home.how_it_works.first_time_user.03.title") }}
                                    </h3>
                                    <p>
                                        {{ $t("pages.home.how_it_works.first_time_user.03.text") }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            class="grid"
                            id="recurrent-user"
                            v-if="toggle == 'recurrent-user'"
                            key="recurrent-user"
                        >
                            <div class="grid-item">
                                <div class="hiw-step-header">
                                    <h3 class="hiw-step-number">01</h3>
                                    <img
                                        height="34"
                                        src="@/assets/images/circle-pad-finger.svg"
                                        alt="icon pad finger"
                                    />
                                </div>
                                <div class="hiw-step-body">
                                    <h3>
                                        {{ $t("pages.home.how_it_works.recurrent_user.01.title") }}
                                    </h3>
                                    <p>
                                        {{ $t("pages.home.how_it_works.recurrent_user.01.text") }}
                                    </p>
                                    <a
                                        href="https://app.storkmobile.com"
                                        class="button button-secondary button-small button-link"
                                        target="_blank"
                                        >{{ $t("button.open_app") }}</a
                                    >
                                </div>
                            </div>
                            <div class="grid-item">
                                <div class="hiw-step-header">
                                    <h3 class="hiw-step-number">02</h3>
                                    <img
                                        height="34"
                                        src="@/assets/images/circle-pad-finger.svg"
                                        alt="icon pad finger"
                                    />
                                </div>
                                <div class="hiw-step-body">
                                    <h3>
                                        {{ $t("pages.home.how_it_works.recurrent_user.02.title") }}
                                    </h3>
                                    <p>
                                        {{ $t("pages.home.how_it_works.recurrent_user.02.text") }}
                                    </p>
                                </div>
                            </div>
                            <div class="grid-item">
                                <div class="hiw-step-header">
                                    <h3 class="hiw-step-number">03</h3>
                                    <img
                                        height="34"
                                        src="@/assets/images/phone-action-check.svg"
                                        alt="icon phone check"
                                    />
                                </div>
                                <div class="hiw-step-body">
                                    <h3>
                                        {{ $t("pages.home.how_it_works.recurrent_user.03.title") }}
                                    </h3>
                                    <p>
                                        {{ $t("pages.home.how_it_works.recurrent_user.03.text") }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </section>
        <div id="home-reviews">
            <section class="home-reviews">
                <div class="container">
                    <h2 class="section-title">{{ $t("title.reviews") }}</h2>
                    <Reviews :limit="2" picked towColumn />
                    <div class="block-lg ts-align-center">
                        <i18n-link class="button" :to="{ name: 'reviews' }">{{
                            $t("button.read_more_reviews")
                        }}</i18n-link>
                    </div>
                </div>
            </section>
        </div>
        <div class="slanted slanted-background-grey slanted-spaced">
            <section class="users-map">
                <div class="container">
                    <h2 class="section-title">{{ $t("title.our_customers_in_the_world") }}</h2>
                    <div class="ts-lead ts-quiet">
                        {{ $t("title.our_customers_in_the_world_sub") }}
                    </div>
                    <div class="hide-tablet">
                        <div class="block-lg">
                            <WorldMap
                                style="max-width: 100%; width: 100%;height: 870px;"
                                v-if="!isMobile && !ssr"
                            />
                        </div>
                    </div>
                    <div
                        class="block-lg show-tablet"
                        v-for="p in plans.filter(p => p.regions && p.slug != 'oceania')"
                        :key="p.slug"
                    >
                        <h2 class="section-title users-map-title">{{ p.name }}</h2>
                        <div class="block-lg">
                            <WorldMap
                                style="max-width: 100%; width: 100%"
                                :region="p.slug"
                                v-if="isMobile && !ssr"
                            />
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <div class="footer-faq-button">
            <i18n-link class="button button-primary" :to="{ name: 'faq' }">{{
                $t("menu.faq")
            }}</i18n-link>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import Reviews from "@/components/Reviews.vue"
import LoadingLazy from "@/components/LoadingLazy.vue"
import { lazyLoadComponent } from "@/utils.js"

export default {
    components: {
        Reviews,
        WorldMap: lazyLoadComponent({
            componentFactory: () =>
                import(/* webpackChunkName: "world-map" */ "@/components/WorldMap.vue"),
            loading: LoadingLazy,
        }),
    },
    meta() {
        return this.$generateMeta({
            description: this.$i18n.t("meta.home.description"),
        })
    },
    data() {
        return {
            total: 0,
            showMore: false,
            toggle: "first-time-user",
        }
    },
    computed: {
        ...mapGetters({ countries: "data/topCountries", plans: "data/plans" }),
        limitedCountries() {
            return !this.showMore ? this.countries.slice(0, 16) : this.countries
        },
        ssr() {
            return typeof __PRERENDER != "undefined" && __PRERENDER.prerender
        },
        isMobile() {
            return window.innerWidth <= 767
        },
    },
    mounted() {
        this.loadCountries()
        this.loadPlans()
    },
    methods: {
        ...mapActions({
            loadCountries: "data/loadTopCountries",
            loadPlans: "data/loadPlans",
        }),
        toggleHowItWorks() {
            if (this.toggle == "first-time-user") {
                this.toggle = "recurrent-user"
            } else {
                this.toggle = "first-time-user"
            }
        },
    },

    watch: {
        "$i18n.locale": {
            handler() {
                this.loadCountries()
                this.loadPlans()
            },
        },
    },
}
</script>

<template>
    <div class="plans">
        <div class="block"></div>

        <div class="container">
            <div class="grid plans-layout">
                <aside class="grid-item plans-sidebar">
                    <h1 class="page-title">Plans</h1>
                </aside>
                <div class="grid-item plans-body">
                    <p
                        class="ts-lead ts-quiet ts-measure"
                        v-html="$tc('pages.plans.top_text', 36)"
                    ></p>
                </div>

                <div class="">
                    <ul class="mobile-nav show-xs">
                        <li class="ts-underline">
                            <router-link :to="`#region`"
                                ><img class="caret" src="@/assets/images/caret-down.svg" />{{
                                    $t("title.regions")
                                }}
                            </router-link>
                        </li>
                        <li class="ts-underline">
                            <router-link :to="`#country`"
                                ><img class="caret" src="@/assets/images/caret-down.svg" />{{
                                    $t("title.countries")
                                }}
                            </router-link>
                        </li>
                        <li class="ts-underline">
                            <i18n-link :to="{ name: 'faq' }"
                                ><img class="caret" src="@/assets/images/caret-down.svg" />{{
                                    $t("menu.faq")
                                }}
                            </i18n-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="block-md"></div>
        <div class="container">
            <div class="grid plans-layout">
                <aside class="grid-item plans-sidebar">
                    <div class="to-affix">
                        <div class="block-lg">
                            <h3>{{ $t("title.regions") }}</h3>
                            <ul class="plans-sidebar-nav">
                                <li v-for="region in regions" :key="`links_${region.id}`">
                                    <router-link :to="`#region-${region.id}`">{{
                                        region.name
                                    }}</router-link>
                                </li>
                            </ul>
                        </div>
                        <div class="block-lg">
                            <h3>{{ $t("title.countries") }}</h3>
                            <div v-if="countries.length <= 0" class="ts-grey">
                                {{ $t("label.coming_soon") }}
                            </div>
                            <ul class="plans-sidebar-nav">
                                <li
                                    v-for="country in countries"
                                    :key="`links_${country.country_code}`"
                                >
                                    <router-link
                                        :to="`#country-${country.country_code.toLowerCase()}`"
                                        >{{ country.name }}</router-link
                                    >
                                </li>
                            </ul>
                        </div>
                        <div class="block-lg">
                            <h3>{{ $t("menu.faq") }}</h3>
                            <ul class="plans-sidebar-nav">
                                <li>
                                    <i18n-link :to="{ name: 'faq' }">{{
                                        $t("menu.faq")
                                    }}</i18n-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </aside>
                <div class="grid-item plans-body next-to-affix" id="region">
                    <h2>{{ $t("title.regions") }}</h2>
                    <div
                        class="block-md"
                        v-for="region in regions"
                        :key="`region_${region.id}`"
                        :id="`region-${region.id}`"
                    >
                        <div class="panel">
                            <div class="panel-header">
                                <div class="panel-head">
                                    <img
                                        :src="require(`@/assets/images/map-${region.slug}.svg`)"
                                        :alt="region.name"
                                        class="mr-1"
                                        width="42"
                                    />
                                    <div>
                                        <h3 class="panel-title ts-lead">{{ region.name }}</h3>
                                        <small>{{
                                            $tc(
                                                "label.supported_countries",
                                                region.countries.length
                                            )
                                        }}</small>
                                    </div>
                                    <div
                                        @click="toggleCoverageRegion(region)"
                                        class="flex-push ts-small ts-quiet ts-pointer hide-xs button button-small button-flat-nohover"
                                    >
                                        <img
                                            class="icon"
                                            height="16px"
                                            src="@/assets/images/icon-coverage.svg"
                                        />
                                        {{ $t("button.coverage_network") }}
                                    </div>
                                </div>
                                <div
                                    @click="toggleCoverageRegion(region)"
                                    class="flex-push ts-small ts-quiet ts-pointer show-xs block-lg button button-small button-flat-nohover"
                                >
                                    <img
                                        class="icon network-icon"
                                        height="16px"
                                        src="@/assets/images/icon-coverage.svg"
                                    />
                                    {{ $t("button.coverage_network") }}
                                </div>
                            </div>
                            <BaseDropdown
                                @input="toggleCoverageRegion(region)"
                                :value="showCoverage[region.id]"
                                classes="coverage panel-body"
                            >
                                <div>
                                    <CoverageList :region="region" v-if="showCoverage[region.id]" />
                                </div>
                                <a slot="close" class="coverage-close ts-pointer">×</a>
                            </BaseDropdown>
                            <div class="panel-body">
                                <div class="list-group">
                                    <div class="list-group-item">
                                        <div class="plans-row">
                                            <div class="plans-row-body ts-quiet">
                                                <div class="plans-row-item left">
                                                    <span>{{ $t("label.plan_data") }}</span>
                                                </div>
                                                <div class="plans-row-item">
                                                    <span>{{ $t("label.plan_duration") }}</span>
                                                </div>
                                                <div class="plans-row-item right">
                                                    <span>{{ $t("label.plan_price") }}</span>
                                                </div>
                                            </div>
                                            <div class="plans-row-push hide-mobile">&nbsp;</div>
                                        </div>
                                    </div>

                                    <div
                                        class="list-group-item"
                                        v-for="plan in region.plans"
                                        :key="`plan_${plan.id}`"
                                    >
                                        <div class="plans-row">
                                            <div class="plans-row-body ts-strong">
                                                <div class="plans-row-item left">
                                                    <span>{{
                                                        humanizeData(
                                                            plan.capacity,
                                                            $t("label.unlimited")
                                                        )
                                                    }}</span>
                                                </div>
                                                <div class="plans-row-item">
                                                    <span>{{
                                                        $tc("label.days", plan.period)
                                                    }}</span>
                                                </div>
                                                <div class="plans-row-item right">
                                                    <span>{{ plan.price | formatPrice }}</span>
                                                </div>
                                            </div>
                                            <div class="plans-row-push">
                                                <a
                                                    :href="getPurchaseUrl(region.id, plan.id)"
                                                    :id="`region-${region.id}-${plan.id}`"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    class="button button-small"
                                                    >{{ $t("button.select") }}</a
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p class="ts-small ts-quiet mt-0 mb-1">* $ = USD</p>
                    </div>
                    <div class="block-xl" id="country"></div>
                    <h2>{{ $t("title.countries") }}</h2>
                    <div v-if="countries.length <= 0" class="ts-grey">
                        {{ $t("label.coming_soon") }}
                    </div>
                    <div
                        class="block-md"
                        v-for="country in countries"
                        :key="`country_${country.country_code}`"
                        :id="`country-${country.country_code.toLowerCase()}`"
                    >
                        <div class="panel">
                            <div class="panel-header">
                                <div class="panel-head">
                                    <picture class="flag">
                                        <source
                                            v-lazy="
                                                `/img/flags/${country.country_code.toLowerCase()}.webp`
                                            "
                                            srcset="/img/flags/earth.webp"
                                            type="image/webp"
                                        />
                                        <source
                                            v-lazy="
                                                `/img/flags/${country.country_code.toLowerCase()}.png`
                                            "
                                            srcset="/img/flags/earth.png"
                                            type="image/png"
                                        />
                                        <img
                                            class="flag"
                                            src="/img/flags/earth.png"
                                            :alt="country.name"
                                        />
                                    </picture>
                                    <h3 class="panel-title">{{ country.name }}</h3>
                                    <div
                                        @click="toggleCoverageCountry(country)"
                                        class="flex-push ts-small ts-quiet ts-pointer hide-xs button button-small button-flat-nohover"
                                    >
                                        <img
                                            class="icon"
                                            height="16px"
                                            src="@/assets/images/icon-coverage.svg"
                                        />
                                        {{ $t("button.network") }}
                                    </div>
                                </div>
                                <div
                                    @click="toggleCoverageCountry(country)"
                                    class="flex-push ts-small ts-quiet ts-pointer show-xs block-lg button button-small button-flat-nohover"
                                >
                                    <img
                                        class="icon network-icon"
                                        height="16px"
                                        src="@/assets/images/icon-coverage.svg"
                                    />
                                    {{ $t("button.network") }}
                                </div>
                            </div>

                            <BaseDropdown
                                @input="toggleCoverageCountry(country)"
                                :value="showNetwork[country.id]"
                                classes="coverage panel-body"
                            >
                                <div>
                                    <CoverageList
                                        :region="country"
                                        network-only
                                        v-if="showNetwork[country.id]"
                                    />
                                </div>
                                <a slot="close" class="coverage-close ts-pointer">×</a>
                            </BaseDropdown>
                            <div class="panel-body">
                                <div class="list-group">
                                    <div class="list-group-item">
                                        <div class="plans-row">
                                            <div class="plans-row-body ts-quiet">
                                                <div class="plans-row-item left">
                                                    <span>{{ $t("label.plan_data") }}</span>
                                                </div>
                                                <div class="plans-row-item">
                                                    <span>{{ $t("label.plan_duration") }}</span>
                                                </div>
                                                <div class="plans-row-item right">
                                                    <span>{{ $t("label.plan_price") }}</span>
                                                </div>
                                            </div>
                                            <div class="plans-row-push hide-mobile">&nbsp;</div>
                                        </div>
                                    </div>

                                    <div
                                        class="list-group-item"
                                        v-for="plan in country.plans"
                                        :key="`plan_${plan.id}`"
                                    >
                                        <div class="plans-row">
                                            <div class="plans-row-body ts-strong">
                                                <div class="plans-row-item left">
                                                    <span>{{
                                                        humanizeData(
                                                            plan.capacity,
                                                            $t("label.unlimited")
                                                        )
                                                    }}</span>
                                                </div>
                                                <div class="plans-row-item">
                                                    <span>{{
                                                        $tc("label.days", plan.period)
                                                    }}</span>
                                                </div>
                                                <div class="plans-row-item right">
                                                    <span>{{ plan.price | formatPrice }}</span>
                                                </div>
                                            </div>
                                            <div class="plans-row-push">
                                                <a
                                                    :href="getPurchaseUrl(country.id, plan.id)"
                                                    :id="`country-${country.id}-${plan.id}`"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    class="button button-small"
                                                    >{{ $t("button.select") }}</a
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p class="ts-small ts-quiet mt-0 mb-1">* $ = USD</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="block-xl"></div>
    </div>
</template>

<script>
import Vue from "vue"
import { humanizeData } from "@/utils"
import CoverageList from "@/components/CoverageList"
import { mapActions, mapGetters } from "vuex"
export default {
    components: { CoverageList },
    meta() {
        return this.$generateMeta({
            title: this.$i18n.t("meta.plans.title"),
            description: this.$i18n.tc("meta.plans.description", 33),
        })
    },
    data() {
        return {
            showCoverage: {},
            showNetwork: {},
        }
    },
    async mounted() {
        this.loadPlans()
        this.loadCountries()
    },
    computed: {
        regions() {
            return this.plans.filter(p => p.regions)
        },
        countries() {
            return this.plans.filter(p => !p.regions)
        },
        ...mapGetters({ plans: "data/plans" }),
    },
    methods: {
        ...mapActions({
            loadCountries: "data/loadTopCountries",
            loadPlans: "data/loadPlans",
        }),
        getPurchaseUrl(countryId, planId) {
            return `${process.env.VUE_APP_APP_BASE_URL}/register/${countryId}-${planId}`
        },
        humanizeData,
        toggleCoverageRegion(region) {
            if (this.showCoverage[region.id]) {
                return Vue.delete(this.showCoverage, region.id)
            }
            return Vue.set(this.showCoverage, region.id, true)
        },
        toggleCoverageCountry(county) {
            if (this.showNetwork[county.id]) {
                return Vue.delete(this.showNetwork, county.id)
            }
            return Vue.set(this.showNetwork, county.id, true)
        },
        changeSelect(value) {
            if (value.target.value) {
                const [type, id] = value.target.value.split(".")
                if (type == "region") {
                    this.$router.replace({ name: this.$route.name, hash: `#region-${id}` })
                } else {
                    this.$router.replace({
                        name: this.$route.name,
                        hash: `#country-${id.toLowerCase()}`,
                    })
                }
            }
        },
    },
    watch: {
        "$i18n.locale": {
            handler() {
                this.loadPlans()
                this.loadCountries()
            },
        },
    },
}
</script>

<style scoped>
.caret {
    margin-right: 8px;
}
</style>

export function loadScript(src) {
    return new Promise((resolve, reject) => {
        const script = document.createElement("script")
        script.type = "text/javascript"
        script.onload = () => {
            return resolve("ok")
        }
        script.src = src
        document.head.appendChild(script)
    })
}

export function humanizeData(capacity, unlimitedLabel = undefined) {
    // capacity が 0 の場合は Unlimited
    if (capacity == 0 && unlimitedLabel) {
        return unlimitedLabel
    }

    if (capacity >= 1000) {
        return `${Math.round((capacity / 1000) * 10) / 10}GB`
    }

    return `${capacity}MB`
}

export function formatPrice(val, currency = "USD") {
    if (val == null) {
        return null
    }
    return val.toLocaleString(navigator.language, { style: "currency", currency })
}

export function timeZone(date) {
    try {
        const str = date.toLocaleString(navigator.language, {
            timeZoneName: "long",
            second: "2-digit",
        })
        return str.substr(2, str.length).trim()
    } catch (error) {
        console.error(error)
        return "-"
    }
}

export function lazyLoadComponent({ componentFactory, loading, loadingData }) {
    let resolveComponent

    return () => ({
        component: new Promise(resolve => {
            resolveComponent = resolve
        }),
        loading: {
            mounted() {
                if (!("IntersectionObserver" in window)) {
                    componentFactory().then(resolveComponent)
                    return
                }

                const observer = new IntersectionObserver(
                    entries => {
                        if (entries[0].intersectionRatio <= 0) return

                        observer.unobserve(this.$el)
                        componentFactory().then(resolveComponent)
                    },
                    {
                        rootMargin: "200px",
                        threshold: 0,
                    }
                )

                observer.observe(this.$el)
            },
            render(createElement) {
                return createElement(loading, loadingData)
            },
        },
    })
}

export const appVersion = "3"

import Vue from "vue"
import Vuex from "vuex"
import { i18n } from "@/plugins/i18n"
import { appVersion } from "@/utils"

class ChargeError extends Error {
    constructor(message, response) {
        super(message)
        this._response = response
    }
    get response() {
        return this._response
    }
}

function getInjected(key) {
    if (typeof INJECTED_DATA != "undefined" && typeof INJECTED_DATA[key] != "undefined") {
        return INJECTED_DATA[key]
    }
    return null
}

export default {
    namespaced: true,
    state: {
        topCountries: [],
        plans: [],
        locations: [],
    },
    getters: {
        topCountries: state => {
            if (state.topCountries.length <= 0 && getInjected("countries")) {
                return getInjected("countries")
            }
            return state.topCountries
        },
        plans: state => state.plans,
        locations: state => state.locations,
    },
    mutations: {
        setTopCountries(state, topCountries) {
            state.topCountries = topCountries
        },
        setPlans(state, plans) {
            state.plans = plans
        },
        setLocations(state, locations) {
            state.locations = locations
        },
        loaded(state) {
            if (state.loadedCallBack) {
                state.loadedCallBack()
            }
            state.loadedCallBack = null
        },
    },
    actions: {
        async loadTopCountries({ commit }) {
            const response = await fetch(
                `${process.env.VUE_APP_API_BASE_URL}/public/top-countries?locale=${i18n.locale}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "app-version": appVersion,
                    },
                }
            )
            const countries = await response.json()
            commit("setTopCountries", countries)
        },
        async loadPlans({ commit }) {
            const response = await fetch(
                `${process.env.VUE_APP_API_BASE_URL}/public/data-plans?locale=${i18n.locale}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "app-version": appVersion,
                    },
                }
            )
            const plans = await response.json()
            commit("setPlans", plans)
        },
        async loadLocations({ commit }) {
            const response = await fetch(
                `${process.env.VUE_APP_API_BASE_URL}/public/live-locations`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "app-version": appVersion,
                    },
                }
            )
            const locations = await response.json()
            commit("setLocations", locations)
        },
        async isEU({ commit }) {
            const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/public/gdpr`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "app-version": appVersion,
                },
            })

            return await response.json()
        },
        async ping({}) {
            const response = await fetch(
                `${process.env.VUE_APP_API_BASE_URL}/public/payment_ping`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "app-version": appVersion,
                    },
                }
            )

            return await response.json()
        },
    },
}

<template>
    <div id="app" class="site-wrapper">
        <div id="network-banner">
            <NetworkAlert />
        </div>
        <Header />
        <main class="site-content" aria-label="Content">
            <router-view />
        </main>
        <CookieBanner />
        <Footer />
    </div>
</template>

<script>
import Header from "@/components/Header"
import Footer from "@/components/Footer"
import CookieBanner from "@/components/CookieBanner"
import NetworkAlert from "@/components/NetworkAlert"
import { mapActions } from "vuex"

export default {
    components: { Header, Footer, CookieBanner, NetworkAlert },
    meta() {
        return {
            titleTemplate: titleChunk => {
                return titleChunk
                    ? `${titleChunk} | ${this.$t("meta.default.middle")}`
                    : this.$t("meta.default.middle")
            },
        }
    },
    async mounted() {
        this.loadCountries()
        this.loadPlans()
    },
    methods: {
        ...mapActions({
            loadCountries: "data/loadTopCountries",
            loadPlans: "data/loadPlans",
        }),
    },
}
</script>

<template>
    <div class="container content-width">
        <div class="block"></div>

        <div class="ts-measure ts-measure-center">
            <h1 class="page-title">{{ $t("pages.get_started.title") }}</h1>
        </div>

        <div class="block-xl"></div>

        <div class="ts-measure-tight">
            <h3>{{ $t("pages.get_started.compatibility") }}</h3>
            <i18n path="pages.get_started.make_sure_device" tag="p" class="ts-lead ts-quiet">
                <i18n-link class="ts-underline" place="link" :to="{ name: 'compatibility' }">{{
                    $t("pages.get_started.esim_supported_devices")
                }}</i18n-link>
            </i18n>
        </div>

        <div class="block-md"></div>

        <div class="grid grid-50 grid-list vertical-align-center">
            <div class="grid-item">
                <div class="hiw-step-header">
                    <h3 class="hiw-step-number">01</h3>
                    <img height="34" src="@/assets/images/circle-pad-finger.svg" />
                </div>
                <div class="hiw-step-body">
                    <h3>{{ $t("pages.get_started.steps.01.title") }}</h3>
                    <i18n path="pages.get_started.steps.01.text" tag="p">
                        <i18n-link class="ts-underline" place="link" :to="{ name: 'plans' }">{{
                            $t("pages.get_started.steps.01.link")
                        }}</i18n-link>
                    </i18n>
                </div>
            </div>
            <div class="grid-item">
                <img
                    v-if="$i18n.locale === 'en'"
                    width="400"
                    src="@/assets/images/screenshot/get-started/en/img01.png"
                />
                <img
                    v-if="$i18n.locale === 'ja'"
                    width="400"
                    src="@/assets/images/screenshot/get-started/ja/img01.png"
                />
            </div>
        </div>
        <div class="grid grid-50 grid-list vertical-align-center">
            <div class="grid-item">
                <div class="hiw-step-header">
                    <h3 class="hiw-step-number">02</h3>
                    <img height="34" src="@/assets/images/icon-payment.svg" />
                </div>
                <div class="hiw-step-body">
                    <h3>{{ $t("pages.get_started.steps.02.title") }}</h3>
                    <p v-html="$t('pages.get_started.steps.02.text')"></p>
                </div>
            </div>
            <div class="grid-item">
                <img
                    v-if="$i18n.locale === 'en'"
                    width="400"
                    src="@/assets/images/screenshot/get-started/en/img02.png"
                />
                <img
                    v-if="$i18n.locale === 'ja'"
                    width="400"
                    src="@/assets/images/screenshot/get-started/ja/img02.png"
                />
            </div>
        </div>
        <div class="grid grid-50 grid-list vertical-align-center">
            <div class="grid-item">
                <div class="hiw-step-header">
                    <h3 class="hiw-step-number">03</h3>
                    <img height="34" src="@/assets/images/qr-code-scan.svg" />
                </div>
                <div class="hiw-step-body">
                    <h3>{{ $t("pages.get_started.steps.03.title") }}</h3>
                    <p v-html="$t('pages.get_started.steps.03.text')"></p>

                    <ul class="setup-links">
                        <li>
                            <i18n-link
                                :to="{ name: 'set-up-guide-ios' }"
                                class="button button-link button-small"
                                hash="HowtoInstall"
                                >{{ $t("pages.get_started.steps.03.link_ios") }}</i18n-link
                            >
                        </li>
                        <li>
                            <i18n-link
                                :to="{ name: 'set-up-guide-android' }"
                                class="button button-link button-small"
                                >{{ $t("pages.get_started.steps.03.link_android") }}</i18n-link
                            >
                        </li>
                    </ul>
                </div>
            </div>
            <div class="grid-item">
                <img
                    v-if="$i18n.locale === 'en'"
                    width="400"
                    src="@/assets/images/screenshot/get-started/en/img03.png"
                />
                <img
                    v-if="$i18n.locale === 'ja'"
                    width="400"
                    src="@/assets/images/screenshot/get-started/ja/img03.png"
                />
            </div>
        </div>
        <div class="grid grid-50 grid-list vertical-align-center">
            <div class="grid-item">
                <div class="hiw-step-header">
                    <h3 class="hiw-step-number">04</h3>
                    <img height="34" src="@/assets/images/phone-action-check.svg" />
                </div>
                <div class="hiw-step-body">
                    <h3>{{ $t("pages.get_started.steps.04.title") }}</h3>
                    <p v-html="$t('pages.get_started.steps.04.text')"></p>

                    <ul class="setup-links">
                        <li>
                            <i18n-link
                                :to="{ name: 'set-up-guide-ios', hash: '#HowToUse' }"
                                class="button button-link button-small"
                                hash="HowtoInstall"
                                >{{ $t("pages.get_started.steps.04.link_ios") }}</i18n-link
                            >
                        </li>
                        <li>
                            <i18n-link
                                :to="{ name: 'set-up-guide-android', hash: '#HowToUse' }"
                                class="button button-link button-small"
                                >{{ $t("pages.get_started.steps.04.link_android") }}</i18n-link
                            >
                        </li>
                    </ul>
                </div>
            </div>
            <div class="grid-item">
                <img
                    v-if="$i18n.locale === 'en'"
                    width="400"
                    src="@/assets/images/screenshot/get-started/en/img04.png"
                />
                <img
                    v-if="$i18n.locale === 'ja'"
                    width="400"
                    src="@/assets/images/screenshot/get-started/ja/img04.png"
                />
            </div>
        </div>
        <div class="block-xl" style="padding-bottom: 5%">
            <h2 class="section-title">
                {{ $t("pages.get_started.dashboard.title") }}
            </h2>
            <div class="mt-2 app-promo-badges">
                <a
                    class="badge-wrap"
                    href="https://apps.apple.com/us/app/stork-mobile-esim-app/id1490956916"
                    target="_blank"
                >
                    <img
                        class="badge"
                        v-lazy="require('@/assets/images/App_Store_badge_EN.svg')"
                        alt="Download for iPhone"
                    />
                </a>
                <a
                    class="badge-wrap"
                    href="https://play.google.com/store/apps/details?id=com.storkmobile.app"
                    target="_blank"
                >
                    <img
                        class="badge"
                        v-lazy="require('@/assets/images/Google_Play_Store_badge_EN.svg')"
                        alt="Download for Android"
                    />
                </a>
            </div>
            <div class="block-lg">
                <div class="grid grid-50 vertical-align-center">
                    <div class="grid-item">
                        <div
                            :class="['bubble', { active: imageToggle === 'balance' }]"
                            @click="imageToggle = 'balance'"
                        >
                            <h3>
                                {{ $t("pages.get_started.dashboard.balance.title") }}
                            </h3>
                            <p>{{ $t("pages.get_started.dashboard.balance.text") }}</p>
                        </div>
                        <div
                            :class="['bubble', { active: imageToggle === 'topup' }]"
                            @click="imageToggle = 'topup'"
                        >
                            <h3>
                                {{ $t("pages.get_started.dashboard.topup.title") }}
                            </h3>
                            <p>{{ $t("pages.get_started.dashboard.topup.text") }}</p>
                        </div>
                        <div
                            :class="['bubble', { active: imageToggle === 'multiple' }]"
                            @click="imageToggle = 'multiple'"
                        >
                            <h3>
                                {{ $t("pages.get_started.dashboard.multiple.title") }}
                            </h3>
                            <p>{{ $t("pages.get_started.dashboard.multiple.text") }}</p>
                        </div>
                    </div>
                    <div class="grid-item">
                        <div class="devices-slideshow">
                            <transition name="fade" mode="out-in">
                                <img
                                    v-if="imageToggle === 'balance'"
                                    key="balance"
                                    src="@/assets/images/iphone-balance.png"
                                />
                                <img
                                    v-if="imageToggle === 'topup'"
                                    key="topup"
                                    src="@/assets/images/iphone-topup.png"
                                />
                                <img
                                    v-if="imageToggle === 'multiple'"
                                    key="multiple"
                                    src="@/assets/images/iphone-multiple.png"
                                />
                            </transition>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="block-xl">
            <h2 class="section-title" id="addHomeScreen">
                {{ $t("pages.get_started.app.title") }}
            </h2>
            <p class="ts-lead ts-quiet ts-measure">{{ $t("pages.get_started.app.text") }}</p>
        </div>
        <div class="block-xl" style="padding-bottom: 10%">
            <div class="grid grid-50">
                <div class="grid-item hooper-width-fix">
                    <div class="panel">
                        <div class="panel-body">
                            <img src="@/assets/images/apple-logo.svg" />
                            <p>{{ $t("pages.get_started.app.iphone.text") }}</p>
                            <hooper :settings="hooperSettings">
                                <slide :index="1">
                                    <img
                                        src="@/assets/images/screenshot/addtohomescreen-ios01.jpg"
                                    />
                                </slide>
                                <slide :index="2">
                                    <img
                                        src="@/assets/images/screenshot/addtohomescreen-ios02.jpg"
                                    />
                                </slide>
                                <slide :index="3">
                                    <img
                                        src="@/assets/images/screenshot/addtohomescreen-ios03.jpg"
                                    />
                                </slide>
                                <hooper-navigation slot="hooper-addons"></hooper-navigation>
                            </hooper>
                        </div>
                    </div>
                </div>
                <div class="grid-item hooper-width-fix">
                    <div class="panel">
                        <div class="panel-body">
                            <img src="@/assets/images/android-logo.svg" />
                            <p>{{ $t("pages.get_started.app.android.text") }}</p>
                            <hooper :settings="hooperSettings">
                                <slide :index="4">
                                    <img
                                        src="@/assets/images/screenshot/addtohomescreen-android01.jpg"
                                    />
                                </slide>
                                <slide :index="5">
                                    <img
                                        src="@/assets/images/screenshot/addtohomescreen-android02.jpg"
                                    />
                                </slide>
                                <slide :index="6">
                                    <img
                                        src="@/assets/images/screenshot/addtohomescreen-android03.jpg"
                                    />
                                </slide>
                                <slide :index="7">
                                    <img
                                        src="@/assets/images/screenshot/addtohomescreen-android04.jpg"
                                    />
                                </slide>
                                <hooper-navigation slot="hooper-addons"></hooper-navigation>
                            </hooper>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer-faq-button">
            <i18n-link class="button button-primary" :to="{ name: 'faq' }">{{
                $t("menu.faq")
            }}</i18n-link>
        </div>
    </div>
</template>
<script>
import "hooper/dist/hooper.css"

export default {
    meta() {
        return this.$generateMeta({
            title: this.$i18n.t("meta.get_started.title"),
            description: this.$i18n.t("meta.get_started.description"),
        })
    },
    components: {
        Hooper: async () => (await import("hooper")).Hooper,
        HooperNavigation: async () => (await import("hooper")).Navigation,
        Slide: async () => (await import("hooper")).Slide,
    },
    data() {
        return {
            imageToggle: "balance",
            hooperSettings: {
                itemsToShow: 1,
                itemsToSlide: 1,
            },
        }
    },
}
</script>

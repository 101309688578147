import Vue from "vue"
import Vuex from "vuex"
import data from "./data"
import createPersistedState from "vuex-persistedstate"
Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        data,
    },
    state: {
        loadedCallBack: null,
    },
    mutations: {
        loadedCallBack(state, callback) {
            state.loadedCallBack = callback
        },
        loaded(state) {
            if (state.loadedCallBack) {
                state.loadedCallBack()
            }
            state.loadedCallBack = null
        },
    },
    actions: {},
    plugins: [
        createPersistedState({
            key: "data",
            paths: [],
        }),
    ],
})

export default store

import Vue from "vue"
import App from "./App.vue"
import { router } from "./router"
import store from "./store"
import { i18n } from "./plugins/i18n"
import generateMeta from "./plugins/generate-meta"
import Lazyload from "./plugins/lazyload"
import Filters from "./plugins/filters"
import ClickOutside from "./plugins/click-outside"
import VueMeta from "vue-meta"
import VueAnalytics from "vue-analytics"
import "./registerServiceWorker"
import "./components/_globals"
import "./assets/styles/styles.scss"

Vue.use(VueMeta, {
    keyName: "meta",
})
Vue.use(VueAnalytics, {
    id: "UA-138171469-1",
    router,
    ecommerce: {
        enabled: true,
    },
    autoTracking: {
        pageviewTemplate(route) {
            return {
                page: route.path,
                title: document.title,
                location: window.location.href,
            }
        },
    },
    debug: {
        sendHitTask: process.env.NODE_ENV === "production",
    },
    disabled: () => {
        return process.env.NODE_ENV !== "production"
    },
})
Vue.use(generateMeta)
Vue.use(Lazyload)
Vue.use(Filters)
Vue.use(ClickOutside)
Vue.config.productionTip = false
Vue.prototype.$apiURL = process.env.VUE_APP_API_BASE_URL
window.addEventListener("beforeinstallprompt", e => {
    e.preventDefault()
})
const app = new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount("#app")

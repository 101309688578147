<template>
    <div class="loader" :style="{ borderWidth: thickness, width: radius, height: radius }"></div>
</template>
<script>
export default {
    props: {
        radius: { type: String, default: "20px" },
        thickness: { type: String, default: "4px" },
    },
}
</script>

<style scoped>
.loader {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
    display: inline-grid;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>

//const FB_APP_ID = "427222638038165"
import { resolve } from "@/router"
export default {
    install(Vue, options) {
        Vue.prototype.$generateMeta = function({
            title,
            description,
            image,
            url,
            type,
            noAlternate = false,
        }) {
            const i18nAlternates = []
            const i18nLinks = []
            const baseRouteName = this.$router.currentRoute.name.slice(0, -3)
            for (const locale of this.$i18n.availableLocales) {
                if (locale != this.$i18n.locale) {
                    i18nAlternates.push({
                        hid: `og:locale:alternate-${locale}`,
                        property: "og:locale:alternate",
                        content: locale,
                    })
                }

                i18nLinks.push({
                    hid: `alternate-hreflang-${locale}`,
                    hreflang: locale,
                    href: resolve({
                        name: `${baseRouteName}_${locale}`,
                        params: this.$router.currentRoute.params,
                        query: this.$router.currentRoute.query,
                    }),
                    rel: "alternate",
                })
            }
            return {
                title: title || "",
                htmlAttrs: {
                    lang: this.$i18n.locale,
                },
                meta: [
                    {
                        hid: "description",
                        name: "description",
                        content: description || this.$t("meta.default.description"),
                    },
                    { hid: "og:type", property: "og:type", content: type || "website" },
                    {
                        hid: "og:title",
                        property: "og:title",
                        content: this.$t("meta.default.middle"),
                    },
                    //{ hid: "fb:app_id", property: "fb:app_id", content: FB_APP_ID },
                    {
                        hid: "og:description",
                        property: "og:description",
                        content: description || "",
                    },
                    {
                        hid: "og:url",
                        property: "og:url",
                        content:
                            url ||
                            `${process.env.VUE_APP_BASE_URL}${this.$router.currentRoute.fullPath}`,
                    },
                    {
                        hid: "og:locale",
                        property: "og:locale",
                        content: this.$i18n.locale,
                    },
                    {
                        hid: "og:image",
                        property: "og:image",
                        content: "https://cdn.storkmobile.com/ogp.png",
                    },
                    {
                        hid: "twitter:card",
                        property: "twitter:card",
                        content: "summary_large_image",
                    },
                    {
                        hid: "twitter:site",
                        property: "twitter:site",
                        content: this.$i18n.locale === "en" ? "@stork_mobile" : "@storkmobile_jp",
                    },
                    ...(noAlternate ? [] : i18nAlternates),
                ],
                link: [
                    ...(noAlternate ? [] : i18nLinks),
                    ...[
                        {
                            rel: "canonical",
                            href:
                                url ||
                                `${process.env.VUE_APP_BASE_URL}${resolve({
                                    name: this.$router.currentRoute.name,
                                    params: this.$router.currentRoute.params,
                                    query: this.$router.currentRoute.query,
                                })}`,
                        },
                    ],
                ],
            }
        }
    },
}

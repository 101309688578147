<template>
    <header class="site-header" role="banner">
        <div class="container">
            <div class="site-header-inner">
                <div class="site-header-item menu">
                    <i18n-link :to="{ name: 'plans' }">{{ $t("menu.plans") }}</i18n-link>
                    <i18n-link :to="{ name: 'getting-started' }">
                        {{ $t("menu.getting_started") }}</i18n-link
                    >
                    <i18n-link :to="{ name: 'reviews' }"> {{ $t("menu.reviews") }}</i18n-link>
                </div>
                <div class="site-header-item mobile-menu">
                    <BaseMenu v-model="menu">
                        <a href="#" slot="activator" class="button">
                            <svg
                                class="icon"
                                width="16"
                                height="14"
                                viewBox="0 0 16 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M0 0H16V2H0V0ZM0 6H16V8H0V6ZM16 12H0V14H16V12Z"
                                    fill="black"
                                />
                            </svg>
                            {{ $t("menu.menu") }}
                        </a>
                        <div class="header-menu">
                            <div class="list-group">
                                <div class="list-group-item short max">
                                    <i18n-link
                                        :to="{ name: 'getting-started' }"
                                        @click.native="menu = false"
                                        >{{ $t("menu.getting_started") }}</i18n-link
                                    >
                                </div>
                                <div class="list-group-item short max">
                                    <i18n-link
                                        :to="{ name: 'plans' }"
                                        @click.native="menu = false"
                                        >{{ $t("menu.plans") }}</i18n-link
                                    >
                                </div>
                                <div class="list-group-item short max">
                                    <i18n-link :to="{ name: 'faq' }" @click.native="menu = false">{{
                                        $t("menu.faq")
                                    }}</i18n-link>
                                </div>
                                <div class="list-group-item short max">
                                    <i18n-link
                                        :to="{ name: 'reviews' }"
                                        @click.native="menu = false"
                                        >{{ $t("menu.reviews") }}</i18n-link
                                    >
                                </div>
                                <div class="list-group-item short no-border">
                                    <div
                                        class="site-header-item mobile-toggle"
                                        @click="menu = false"
                                    >
                                        <LanguageMenu />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </BaseMenu>
                </div>
                <div class="site-header-item logo">
                    <i18n-link :to="{ name: 'home' }">
                        <img class="site-logo" src="@/assets/images/logo.png" alt="Stork Mobile" />
                    </i18n-link>
                </div>
                <div class="site-header-item menu-right">
                    <a href="https://app.storkmobile.com/login" class="button">{{
                        $t("button.log_in")
                    }}</a>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import LanguageMenu from "./LanguageMenu"
export default {
    components: { LanguageMenu },
    data() {
        return {
            menu: false,
            setupMenu: false,
        }
    },
}
</script>
